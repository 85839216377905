import { DatePicker, Table } from 'antd';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  LinearScale
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Await, defer, useFetcher, useLoaderData } from 'react-router-dom';
import { getEnquiriesConversion, getInsightToday, getInsights, getMonthlyEnquiries, getProjectsComparison, getSalesLeaderboard, getSalesOverYears, getTurnoverComparison } from '../../api/reporting/dashboard.js';
import { StatCard, StatCardSkeleton } from '../../components/design';
import { Box } from '../../components/design/box.js';
import { Page } from '../../components/page';
import { useUpdateQueryStringValueWithoutNavigation } from '../../hooks/use-update-query-string-without-navigation.js';
import { ERP_CURRENCY } from '../../library/constants/dynamic.js';
import { formats } from '../../library/constants/formats.js';
import { formatPrice, roundOfNumber } from '../../library/utilities/intl.js';
import styles from './dashboard.module.scss';

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const dateFormat = formats.frontendDateFormat
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const ranges = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'This Week': [moment().startOf('week'), moment().endOf('week')],
  'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  'This Year': [moment().startOf('year'), moment().endOf('year')],
  'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
}

function TodayInsight({ dataPromise }) {
  return (
    <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(4, 1fr)' }}>
      <React.Suspense fallback={<>
        <StatCardSkeleton title="New Enquiry" icon={<img src="/icons/Dashboard/insight-for-today/new-enquiry.svg" style={{ width: '1.25rem', verticalAlign: 'sub' }} />} />
        <StatCardSkeleton title="Due Date" icon={<img className={styles.iconImg} src="/icons/Dashboard/insight-for-today/due-date.svg" />} />
        <StatCardSkeleton title="Outstanding" icon={<img className={styles.iconImg} src="/icons/Dashboard/insight-for-today/outstanding.svg" />} />
        <StatCardSkeleton title="Waiting For Invoice" icon={<img className={styles.iconImg} src="/icons/Dashboard/insight-for-today/waiting-for-invoice.svg" />} />
      </>}>
        <Await resolve={dataPromise} errorElement={<></>} >
          {(data = {}) => (
            <>
              <StatCard title="New Enquiry" icon={<img className={styles.iconImg} src="/icons/Dashboard/insight-for-today/new-enquiry.svg" />} data={data.new_enquiries} />
              <StatCard title="Due Date" data={data.due_invoices} icon={<img className={styles.iconImg} src="/icons/Dashboard/insight-for-today/due-date.svg" />} />
              <StatCard title="Outstanding" icon={<img className={styles.iconImg} src="/icons/Dashboard/insight-for-today/outstanding.svg" />} data={formatPrice(data.outstanding_amount, ERP_CURRENCY)} />
              <StatCard title="Waiting For Invoice" icon={<img className={styles.iconImg} src="/icons/Dashboard/insight-for-today/waiting-for-invoice.svg" />} data={formatPrice(data.waiting_for_invoice_amount, ERP_CURRENCY)} />
            </>
          )}
        </Await>
      </React.Suspense>
    </div>
  )
}
TodayInsight.propTypes = {
  dataPromise: PropTypes.shape({
    then: PropTypes.func.isRequired,
    catch: PropTypes.func.isRequired
  }).isRequired
}

function Insights({ data }) {
  // If `data` is not a Promise, wrap it in `Promise.resolve`
  const dataOrPromise = useMemo(() => {
    return typeof data?.then === 'function'
      ? data
      : Promise.resolve(data)
  }, [data])

  const renderSkeletons = (
    <>
      <StatCardSkeleton
        type={StatCard.Types.GRAY}
        title="Total Created Projects"
        icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/total-created-projects.svg" />}
      />
      <StatCardSkeleton
        type={StatCard.Types.GRAY}
        title="Quotation Sent"
        icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/quotation-sent.svg" />}
      />
      <StatCardSkeleton
        type={StatCard.Types.GRAY}
        title="Confirmed Projects"
        icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/confirm-projects.svg" />}
      />
      <StatCardSkeleton
        type={StatCard.Types.GRAY}
        title="Total Turnover"
        icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/total-turnover.svg" />}
      />
      <StatCardSkeleton
        type={StatCard.Types.GRAY}
        title="Avg margin %"
        icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/avg-margin.svg" />}
      />
      <StatCardSkeleton
        type={StatCard.Types.GRAY}
        title="Web Order"
        icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/web-order.svg" />}
      />
      <StatCardSkeleton
        type={StatCard.Types.GRAY}
        title="Web Order Margin"
        icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/web-order-margin.svg" />}
      />
      <StatCardSkeleton
        type={StatCard.Types.GRAY}
        title="Potential Enquiry Value"
        icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/potential-enquiry-value.svg" />}
      />
      <StatCardSkeleton
        type={StatCard.Types.GRAY}
        title="Unique Client"
        icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/unique-client.svg" />}
      />
      <StatCardSkeleton
        type={StatCard.Types.GRAY}
        title="Lost Projects"
        icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/lost-project.svg" />}
      />
      <StatCardSkeleton
        type={StatCard.Types.GRAY}
        title="Close Lost Value"
        icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/close-lost-value.svg" />}
      />
      <StatCardSkeleton
        type={StatCard.Types.GRAY}
        title="Received Amount"
        icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/received-amount.svg" />}
      />
    </>
  );


  return (
    <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(4, 1fr)' }}>
      <React.Suspense fallback={renderSkeletons}>
        <Await resolve={dataOrPromise} errorElement={<></>}>
          {(resolvedData = {}) => (
            <>
              <StatCard
                type={StatCard.Types.GRAY}
                title="Total Created Projects"
                icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/total-created-projects.svg" />}
                data={resolvedData.projects_created}
              />
              <StatCard
                type={StatCard.Types.GRAY}
                title="Quotation Sent"
                icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/quotation-sent.svg" />}
                data={resolvedData.quotations_sent}
              />
              <StatCard
                type={StatCard.Types.GRAY}
                title="Confirmed Projects"
                icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/confirm-project.svg" />}
                data={resolvedData.projects_confirmed}
              />
              <StatCard
                type={StatCard.Types.GRAY}
                title="Total Turnover"
                icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/total-turnover.svg" />}
                data={formatPrice(resolvedData.turnover, ERP_CURRENCY)}
              />
              <StatCard
                type={StatCard.Types.GRAY}
                title="Avg margin %"
                icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/avg-margin.svg" />}
                data={`${roundOfNumber(resolvedData.average_margin)} %`}
              />
              <StatCard
                type={StatCard.Types.GRAY}
                title="Web Order"
                icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/web-order.svg" />}
                data={formatPrice(resolvedData.web_orders_value, ERP_CURRENCY)}
              />
              <StatCard
                type={StatCard.Types.GRAY}
                title="Web Order Margin"
                icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/web-order-margin.svg" />}
                data={`${roundOfNumber(resolvedData.web_orders_margin)} %`}
              />
              <StatCard
                type={StatCard.Types.GRAY}
                title="Potential Enquiry Value"
                icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/potential-enquiry-value.svg" />}
                data={formatPrice(resolvedData.potential_enquiry_value, ERP_CURRENCY)}
              />
              <StatCard
                type={StatCard.Types.GRAY}
                title="Unique Client"
                icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/unique-client.svg" />}
                data={resolvedData.unique_clients}
              />
              <StatCard
                type={StatCard.Types.GRAY}
                title="Lost Projects"
                icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/lost-project.svg" />}
                data={resolvedData.projects_lost}
              />
              <StatCard
                type={StatCard.Types.GRAY}
                title="Close Lost Value"
                icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/close-lost-value.svg" />}
                data={formatPrice(resolvedData.close_lose_value, ERP_CURRENCY)}
              />
              <StatCard
                type={StatCard.Types.GRAY}
                title="Received Amount"
                icon={<img className={styles.iconImg} src="/icons/Dashboard/insights/received-amount.svg" />}
                data={formatPrice(resolvedData.amount_recieved, ERP_CURRENCY)}
              />
            </>
          )}
        </Await>
      </React.Suspense>
    </div>
  )
}
Insights.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape({
      then: PropTypes.func.isRequired,
      catch: PropTypes.func.isRequired
    }),
    PropTypes.object
  ]).isRequired
}


function TurnOverComparison({ data, year1, year2 }) {
  const colors = {
    year1: '#4178d1',
    year2: '#a5c7ff',
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        // align: 'start',
      },
    },
  }

  return (
    <React.Suspense>
      <Await resolve={data} errorElement={<Bar options={options} data={{
        labels: months,
        datasets: [
          {
            label: year1,
            data: [],
            backgroundColor: colors.year1,
          },
          {
            label: year2,
            data: [],
            backgroundColor: colors.year2,
          },
        ]
      }} />} >
        {(data = []) => {
          return (
            <Bar options={options} data={{
              labels: months,
              datasets: [
                {
                  label: year1,
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.data1 ?? 0),
                  backgroundColor: colors.year1,
                },
                {
                  label: year2,
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.data2 ?? 0),
                  backgroundColor: colors.year2,
                },
              ]
            }} />
          )
        }}
      </Await>
    </React.Suspense>
  )
}
TurnOverComparison.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape({
      then: PropTypes.func.isRequired,
      catch: PropTypes.func.isRequired
    }),
    PropTypes.array
  ]).isRequired,
  year1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  year2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

function ProjectsComparison({ data, year1, year2 }) {
  const colors = {
    year1: '#E8AA4D',
    year2: '#F6D97C',
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        // align: 'start',
      },
    },
  }

  return (
    <React.Suspense>
      <Await resolve={data} errorElement={<Bar options={options} data={{
        labels: months,
        datasets: [
          {
            label: year1,
            data: [],
            backgroundColor: colors.year1,
          },
          {
            label: year2,
            data: [],
            backgroundColor: colors.year2,
          },
        ]
      }} />} >
        {(data = []) => {
          return (
            <Bar options={options} data={{
              labels: months,
              datasets: [
                {
                  label: year1,
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.data1 ?? 0),
                  backgroundColor: colors.year1,
                },
                {
                  label: year2,
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.data2 ?? 0),
                  backgroundColor: colors.year2,
                },
              ]
            }} />
          )
        }}
      </Await>
    </React.Suspense>
  )
}
ProjectsComparison.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape({
      then: PropTypes.func.isRequired,
      catch: PropTypes.func.isRequired
    }),
    PropTypes.object
  ]).isRequired,
  year1: PropTypes.string.isRequired,
  year2: PropTypes.string.isRequired,
}

function SalesOverYears({ dataPromise }) {
  const colors = ['#4178D1', '#23B27C', '#FFA590', '#F6D97C', '#FFEEB9', '#A1E1B9', '#B7D1FB']

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        // align: 'start',
      },
    },
  }

  return (
    <React.Suspense>
      <Await resolve={dataPromise} errorElement={<Line options={options} data={{
        labels: months,
        datasets: []
      }} />} >
        {(data = []) => {
          return (
            <Line options={options} data={{
              labels: months,
              datasets: Object.entries(data).map(([year, turnover], i) => (
                {
                  label: year,
                  data: months.map((_, monthIndex) => turnover.find(v => parseInt(v.month) === (monthIndex + 1))?.sum ?? 0),
                  backgroundColor: colors[i] ?? '#4178D1',
                  borderColor: colors[i] ?? '#4178D1',
                }
              ))
            }} />
          )
        }}
      </Await>
    </React.Suspense>
  )
}
SalesOverYears.propTypes = {
  dataPromise: PropTypes.shape({
    then: PropTypes.func.isRequired,
    catch: PropTypes.func.isRequired
  }).isRequired,
}

const LeaderboardProgress = ({ val, maxVal }) => (
  <div style={{
    width: `${(val / maxVal) * 100}%`,
    background: '#23b17c',
    height: '25px',
    borderRadius: '5px',
    color: 'white',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '5px',
    minWidth: 'fit-content',
  }}>{formatPrice(val, ERP_CURRENCY)}</div>
)
LeaderboardProgress.propTypes = {
  val: PropTypes.number.isRequired,
  maxVal: PropTypes.number.isRequired,
}

const Leaderboard = ({ data }) => {
  return (
    <React.Suspense fallback={<p>Loading Leaderboard...</p>}>
      <Await resolve={data} errorElement={<></>}>
        {(salesData) => {
          const maxTurnover = salesData?.reduce((prev, current) => (prev && prev > current.total_price) ? prev : current.total_price, 0)
          return <Table
            columns={[
              {
                title: 'Account Manager',
                dataIndex: 'name',
              },
              {
                title: 'Turnover',
                dataIndex: 'total_price',
                render: val => <LeaderboardProgress val={val} maxVal={maxTurnover} />,
              },
              {
                title: 'Margin',
                dataIndex: 'total_margin',
                render: val => `${roundOfNumber(val, 2)} %`
              },
              {
                title: 'Adjusted Result',
                dataIndex: 'total_price_with_margin',
                render: val => formatPrice(val, ERP_CURRENCY)
              },
            ]}
            dataSource={salesData}
            rowKey='id'
            pagination={false}
          />
        }}
      </Await>
    </React.Suspense>

  )
}
Leaderboard.propTypes = {
  data: PropTypes.shape({
    then: PropTypes.func.isRequired,
    catch: PropTypes.func.isRequired
  }).isRequired,
}

function EnqiriesCount({ data }) {
  const colors = {
    website: '#B7D1FB',
    call: '#FFEEB9',
    email: '#A1E1B9',
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        // align: 'start',
      },
      datalabels: {
        display: true,
        formatter: (v) => (v && v > 0) ? v : '',
        color: 'black',
        font: {
          weight: 'bold',
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  }

  return (
    <React.Suspense>
      <Await resolve={data} errorElement={<Bar options={options} data={{
        labels: months,
        datasets: []
      }} plugins={[ChartDataLabels]} />} >
        {(data = []) => {
          return (
            <Bar options={options} data={{
              labels: months,
              datasets: [
                {
                  label: 'Website',
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.website ?? 0),
                  backgroundColor: colors.website,
                },
                {
                  label: 'E-mail',
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.email ?? 0),
                  backgroundColor: colors.email,
                },
                {
                  label: 'Call',
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.phone ?? 0),
                  backgroundColor: colors.call,
                },
              ]
            }} plugins={[ChartDataLabels]} />
          )
        }}
      </Await>
    </React.Suspense>
  )
}
EnqiriesCount.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape({
      then: PropTypes.func.isRequired,
      catch: PropTypes.func.isRequired
    }),
    PropTypes.object
  ]).isRequired,
}

function EnquiriesConverion({ data }) {
  const colors = {
    total: '#FFDAD1',
    converted: '#FFA590',
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        // align: 'start',
      },
      datalabels: {
        display: true,
        formatter: (v) => (v && v > 0) ? v : '',
        color: 'black',
        font: {
          weight: 'bold',
        },
      },
    },
  }

  return (
    <React.Suspense>
      <Await resolve={data} errorElement={<Bar options={options} data={{
        labels: months,
        datasets: []
      }} plugins={[ChartDataLabels]} />} >
        {(data = []) => {
          return (
            <Bar options={options} data={{
              labels: months,
              datasets: [
                {
                  label: 'New Enquiries',
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.data1 ?? 0),
                  backgroundColor: colors.total,
                },
                {
                  label: 'Converted',
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.data2 ?? 0),
                  backgroundColor: colors.converted,
                },
              ]
            }} plugins={[ChartDataLabels]} />
          )
        }}
      </Await>
    </React.Suspense>
  )
}
EnquiriesConverion.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape({
      then: PropTypes.func.isRequired,
      catch: PropTypes.func.isRequired
    }),
    PropTypes.object
  ]).isRequired,
}


function DataBox({ header, data, loader, component, input = {} }) {
  const fetcher = useFetcher()

  // Memoize the input to avoid unnecessary re-renders
  const memoizedInput = useMemo(() => input, [JSON.stringify(input)]);

  // Track if it's the first render
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the fetch on the first render
      isFirstRender.current = false;
      return;
    }

    const params = new URLSearchParams()
    params.delete('index')
    params.set('loader', loader)
    Object.entries(memoizedInput).forEach(([k, v]) => params.set(k, v))
    fetcher.load(`?${params.toString()}`)
  }, [loader, memoizedInput])

  return (
    <Box header={header}>
      {component({ ...data, ...(fetcher.data && { data: Promise.resolve(fetcher.data) }) })}
    </Box>
  )
}

DataBox.propTypes = {
  header: PropTypes.node,
  loader: PropTypes.string.isRequired,
  data: PropTypes.shape({
    data: PropTypes.shape({
      then: PropTypes.func.isRequired,
      catch: PropTypes.func.isRequired
    }).isRequired,
  }).isRequired,
  component: PropTypes.func.isRequired, // A function that renders the child component
  input: PropTypes.object
}

function Dashboard({ title }) {
  const { insightToday, insights, turnoverComparison, salesLeaderboard, salesOverYears, projectsComparison, enquiriesCount, enquiriesConversion } = useLoaderData()
  const [dateRangeParam, setDateRangeParam] = useUpdateQueryStringValueWithoutNavigation('daterange')
  const [year1Param, setYear1Param] = useUpdateQueryStringValueWithoutNavigation('year1')
  const [year2Param, setYear2Param] = useUpdateQueryStringValueWithoutNavigation('year2')
  const [enquiriesYearParam, setEnquiriesYearParam] = useUpdateQueryStringValueWithoutNavigation('enquiriesyear')

  const dateRange = dateRangeParam?.split(',').map(v => v ? moment(v, formats.backendDateFormat) : null)
  const [year1, year2] = [
    year1Param ? moment(year1Param, 'YYYY') : moment(),
    year2Param ? moment(year2Param, 'YYYY') : moment().subtract(1, 'year'),
  ]
  const enquiriesYear = enquiriesYearParam ? moment(enquiriesYearParam, 'YYYY') : moment()

  return (
    <Page className='dashboard' title={title} backLink={false}>
      <Box type={Box.BoxTypes.BLUE} containerStyles={{ border: '#97B9EF 1px solid' }}>
        <h3>Insight for today</h3>
        <TodayInsight dataPromise={insightToday} />
      </Box>
      <DataBox header={
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h3>Insights</h3>
          <DatePicker.RangePicker
            defaultValue={dateRange}
            ranges={ranges}
            disabledDate={(current) => current.isAfter(moment())}
            format={dateFormat}
            onChange={range => setDateRangeParam(range ? range.map(d => d.format(formats.backendDateFormat)).join(',') : '')}
          />
        </div>
      }
        loader='insights'
        component={Insights}
        data={{ data: insights }}
        input={{ daterange: dateRangeParam }}
      />
      <DataBox header={
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h3>Turnover vs Target</h3>
          <div>
            <DatePicker
              picker="year"
              defaultValue={year1}
              onChange={(_, v) => setYear1Param(v)}
            />
            <DatePicker
              picker="year"
              defaultValue={year2}
              onChange={(_, v) => setYear2Param(v)}
            />
          </div>
        </div>
      }
        loader='turnoverComparison'
        component={TurnOverComparison}
        data={{ data: turnoverComparison, year1: year1Param, year2: year2Param }}
        input={{ year1: year1Param, year2: year2Param }}
      />
      <DataBox header={
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h3>Sales Leaderboard</h3>
          <DatePicker.RangePicker
            defaultValue={dateRange}
            ranges={ranges}
            disabledDate={(current) => current.isAfter(moment())}
            format={dateFormat}
            onChange={range => setDateRangeParam(range ? range.map(d => d.format(formats.backendDateFormat)).join(',') : '')}
          />
        </div>
      }
        loader='salesLeaderboard'
        component={Leaderboard}
        data={{ data: salesLeaderboard }}
        input={{ daterange: dateRangeParam }}
      />
      <Box header={
        <h3>Sales over the Years</h3>
      }>
        <SalesOverYears dataPromise={salesOverYears} />
      </Box>
      <DataBox header={
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h3>Projects Compared With Previous Year</h3>
          <div>
            <DatePicker
              picker="year"
              defaultValue={year1}
              onChange={(_, v) => setYear1Param(v)}

            />
            <DatePicker
              picker="year"
              defaultValue={year2}
              onChange={(_, v) => setYear2Param(v)}
            />
          </div>
        </div>
      }
        loader='projectsComparison'
        component={ProjectsComparison}
        data={{ data: projectsComparison, year1: year1Param, year2: year2Param }}
        input={{ year1: year1Param, year2: year2Param }}
      />
      <DataBox header={
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h3>Enquiries</h3>
          <div>
            <DatePicker
              picker="year"
              defaultValue={enquiriesYear}
              onChange={(_, v) => setEnquiriesYearParam(v)}
            />
          </div>
        </div>
      }
        loader='enquiriesCount'
        component={EnqiriesCount}
        data={{ data: enquiriesCount }}
        input={{ enquiriesYearParam }}
      />
      <DataBox header={
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h3>Enquiry Conversion</h3>
          <div>
            <DatePicker
              picker="year"
              defaultValue={enquiriesYear}
              onChange={(_, v) => setEnquiriesYearParam(v)}
            />
          </div>
        </div>
      }
        loader='enquiriesConversion'
        component={EnquiriesConverion}
        data={{ data: enquiriesConversion }}
        input={{ enquiriesYearParam }}
      />
    </Page >
  )
}
Dashboard.Loaders = {
  default: async ({ request }) => {
    const searchParams = new URL(request.url).searchParams
    let hasInvalidParams = false
    // const normalizedParams = new URLSearchParams(searchParams)
    const dateRange = searchParams.get('daterange')?.split(',').map(v => v && moment(v, formats.backendDateFormat, true).isValid() ? v : null)
    const [year1, year2] = [
      searchParams.has('year1') && moment(searchParams.get('year1'), 'YYYY', true).isValid() ? searchParams.get('year1') : null,
      searchParams.has('year2') && moment(searchParams.get('year2'), 'YYYY', true).isValid() ? searchParams.get('year2') : null,
    ]
    const enquiriesYear = searchParams.has('enquiriesyear') && moment(searchParams.get('enquiriesyear'), 'YYYY', true).isValid() ? searchParams.get('enquiriesyear') : null

    if (searchParams.has('daterange') && dateRange.filter(v => v !== null).length < 2) {
      searchParams.delete('daterange')
      hasInvalidParams = true
    }

    if (searchParams.has('year1') && !year1) {
      searchParams.delete('year1')
      hasInvalidParams = true
    }

    if (searchParams.has('year2') && !year2) {
      searchParams.delete('year2')
      hasInvalidParams = true
    }

    if (searchParams.has('enquiriesyear') && !enquiriesYear) {
      searchParams.delete('enquiriesyear')
      hasInvalidParams = true
    }

    if (hasInvalidParams) {
      const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`
      // Redirect to the new URL
      window.location.href = newUrl
    }

    // Ensure dateRange has valid values and only pass valid dates
    const dateRangeStart = dateRange?.[0] && moment(dateRange[0], formats.backendDateFormat, true).isValid() ? dateRange[0] : null;
    const dateRangeEnd = dateRange?.[1] && moment(dateRange[1], formats.backendDateFormat, true).isValid() ? dateRange[1] : null;

    return defer({
      insightToday: getInsightToday(),
      insights: getInsights(dateRangeStart, dateRangeEnd),
      turnoverComparison: getTurnoverComparison(year1, year2),
      salesLeaderboard: getSalesLeaderboard(dateRangeStart, dateRangeEnd),
      salesOverYears: getSalesOverYears(),
      projectsComparison: getProjectsComparison(year2, year2),
      enquiriesCount: getMonthlyEnquiries(enquiriesYear),
      enquiriesConversion: getEnquiriesConversion(enquiriesYear),
    })
  },
  insights: async ({ request }) => {
    const searchParams = new URL(request.url).searchParams
    // const normalizedParams = new URLSearchParams(searchParams)
    const dateRange = searchParams.get('daterange')?.split(',').map(v => v && moment(v, formats.backendDateFormat, true).isValid() ? v : null)

    // Ensure dateRange has valid values and only pass valid dates
    const dateRangeStart = dateRange?.[0] && moment(dateRange[0], formats.backendDateFormat, true).isValid() ? dateRange[0] : null;
    const dateRangeEnd = dateRange?.[1] && moment(dateRange[1], formats.backendDateFormat, true).isValid() ? dateRange[1] : null;

    return await getInsights(dateRangeStart, dateRangeEnd)
  },
  turnoverComparison: async ({ request }) => {
    const searchParams = new URL(request.url).searchParams
    const [year1, year2] = [
      searchParams.has('year1') && moment(searchParams.get('year1'), 'YYYY', true).isValid() ? searchParams.get('year1') : null,
      searchParams.has('year2') && moment(searchParams.get('year2'), 'YYYY', true).isValid() ? searchParams.get('year2') : null,
    ]

    if (!year1) {
      searchParams.delete('year1')
    }

    if (!year2) {
      searchParams.delete('year2')
    }

    return await getTurnoverComparison(year1, year2)
  },
  salesLeaderboard: async ({ request }) => {
    const searchParams = new URL(request.url).searchParams
    // const normalizedParams = new URLSearchParams(searchParams)
    const dateRange = searchParams.get('daterange')?.split(',').map(v => v && moment(v, formats.backendDateFormat, true).isValid() ? v : null)

    // Ensure dateRange has valid values and only pass valid dates
    const dateRangeStart = dateRange?.[0] && moment(dateRange[0], formats.backendDateFormat, true).isValid() ? dateRange[0] : null;
    const dateRangeEnd = dateRange?.[1] && moment(dateRange[1], formats.backendDateFormat, true).isValid() ? dateRange[1] : null;

    return await getSalesLeaderboard(dateRangeStart, dateRangeEnd)
  },
  projectsComparison: async ({ request }) => {
    const searchParams = new URL(request.url).searchParams
    const [year1, year2] = [
      searchParams.has('year1') && moment(searchParams.get('year1'), 'YYYY', true).isValid() ? searchParams.get('year1') : null,
      searchParams.has('year2') && moment(searchParams.get('year2'), 'YYYY', true).isValid() ? searchParams.get('year2') : null,
    ]

    if (!year1) {
      searchParams.delete('year1')
    }

    if (!year2) {
      searchParams.delete('year2')
    }

    return await getProjectsComparison(year1, year2)
  },
  enquiriesCount: async ({ request }) => {
    const searchParams = new URL(request.url).searchParams
    const enquiriesYear = searchParams.has('enquiriesyear') && moment(searchParams.get('enquiriesyear'), 'YYYY', true).isValid() ? searchParams.get('enquiriesyear') : null

    return await getMonthlyEnquiries(enquiriesYear)
  },
  enquiriesConversion: async ({ request }) => {
    const searchParams = new URL(request.url).searchParams
    const enquiriesYear = searchParams.has('enquiriesyear') && moment(searchParams.get('enquiriesyear'), 'YYYY', true).isValid() ? searchParams.get('enquiriesyear') : null

    return await getEnquiriesConversion(enquiriesYear)
  },
}

Dashboard.propTypes = { ...Page.propTypes }

export default Dashboard
