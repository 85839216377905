import { Button, Collapse, message, Table } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Await, defer, generatePath, redirect, useFetcher, useLoaderData, useNavigate, useParams } from 'react-router-dom'
import { updateContact } from '../../api/contact/contact.js'
import { getContact, getContactOrders } from '../../api/contact/contact[id].js'
import { createProject } from '../../api/project'
import { HubspotButton } from '../../components/data/hubspot-viewer.jsx'
import { Contact } from '../../components/form'
import { Page } from '../../components/page'
import { ValidationError } from '../../library/classes/exceptions/index.js'
import { ERP_CURRENCY } from '../../library/constants/dynamic.js'
import { getOrderStatusName } from '../../library/constants/steps/order-manager-steps.js'
import { formatDateTime, formatPrice } from '../../library/utilities/intl.js'
import { generateRouteUrl } from '../../library/constants/index.js'
const { Panel } = Collapse

const OrderColumns = [
	{
		title: 'Order ID',
		dataIndex: 'item_id'
	},
	{
		title: 'Order Date',
		dataIndex: 'createdAt',
		render: date => formatDateTime(date)
	},
	{
		title: 'Product',
		dataIndex: 'product_name'
	},
	{
		title: 'Price',
		dataIndex: 'total_price',
		render: price => formatPrice(price, ERP_CURRENCY)
	},
	{
		title: 'Status',
		dataIndex: 'order_status',
		render: (status) => getOrderStatusName(status) ?? '',
	},
]

const ContactDetails = ({ title }) => {
	const { id: contactId } = useParams()
	const { contactDetails, orders } = useLoaderData()
	const [enable, setEnable] = useState(false)
	const navigate = useNavigate()
	const fetcher = useFetcher()

	useEffect(() => {
		setEnable(false)
	}, [contactDetails])
	const makeProject = async (payload) => {
		try {
			const { message: msg, data } = await createProject({ ...payload })
			message.success(msg)
			navigate(generatePath('/projects/:id', {
				id: data.id
			}))
		}
		catch (e) {
			message.error(e.message)
			throw e
		}
	}
	const handleCustomerDetailContactsSubmit = (values) => {
		fetcher.submit({
			...values,
			action: 'updateContact'
		}, {
			method: 'post',
			encType: 'application/json'
		})
	}

	return (
		<Page className='customers-detail' title={title}>
			{contactDetails &&
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header={<><img src="/icons/headericons/contacts.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }} />Contact Details</>} key="1" extra={
						<div className='actions' onClick={(event) => event.stopPropagation()}>
							<Button disabled={enable} onClick={() => setEnable(true)}> Edit</Button>
							{!!contactDetails.hubspot_url && <HubspotButton url={contactDetails.hubspot_url} />}
							<Button
								onClick={() => {
									makeProject({
										'source_id': 'email',
										'account_manager_id': '1',
										'customer_id': contactDetails.customer_id,
										'primary_contact_id': contactDetails.id
									})
								}}
							>Create Project</Button>
						</div>
					}>
						{enable ? (
							<Contact errors={fetcher.data?.errors} id={Number(contactId)} initialValues={contactDetails} disabled={!enable} onFinish={handleCustomerDetailContactsSubmit} isUpdate>
								<Button type="primary" htmlType="submit">Save</Button>
								<Button className="cancelButton" onClick={() => {
									setEnable(false)
								}}>Cancel</Button>
							</Contact>
						) : (
							<Contact.Disabled data={contactDetails} />
						)}
					</Panel>
				</Collapse>
			}
			<React.Suspense>
				<Await resolve={orders}>
					{(orders) => (
						<Collapse defaultActiveKey={['1']} expandIconPosition="end">
							<Panel header={<><img src="/icons/headericons/orders.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }} />Orders</>} key="1">
								<Table
									columns={OrderColumns}
									dataSource={orders}
									rowKey="item_id"
									pagination={{
										hideOnSinglePage: true
									}}
									onRow={row => ({
										onClick: () => {
											// Handle the row click event
											navigate(generateRouteUrl('OrderManagerDetailsPage', {
												itemId: row.item_id // Pass the item_id of the clicked row
											}))
										}
									})}
								/>
							</Panel>
						</Collapse>
					)}
				</Await>
			</React.Suspense>
		</Page>
	)
}

ContactDetails.propTypes = {
	title: PropTypes.string
}

ContactDetails.Actions = {
	updateContact: async ({ params, data }) => {
		try {
			const { message: msg } = await updateContact(params.id, data)
			message.success(msg)
			return redirect(generatePath('/customers/contacts/:id', {
				id: params.id //maybe to some other route
			}))
		}
		catch (error) {
			message.error(error.message)

			if (error instanceof ValidationError) {
				const formattedErrors = {
				}
				Object.entries(error.errors).forEach(([k, v]) => {
					formattedErrors[k] = v
				})

				return {
					errors: formattedErrors
				}
			}
		}
		return null
	},
}

ContactDetails.Loader = async ({ params }) => {
	if (isNaN(params.id)) {
		throw new Error('Invalid Contact ID')
	}
	const res = await getContact(params.id)
	if (!res) {
		throw new Error('Invalid Contact')

	}
	const contactDetails = res.data


	return defer({
		contactDetails,
		orders: getContactOrders(params.id)
	})

}

export default ContactDetails