import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Form } from 'react-router-dom';
import OrderManagerStudio from '../../components/data/studio/orderMangerStudio';
import SendToStudio from '../../components/data/studio/sendTOstudio';
import { Tabs } from '../../components/design';
import { ArtworkSelector } from '../../components/form';
import { Languages } from '../../library/constants/dynamic.js';
import { getPreviewUrl } from '../../library/utilities/urls.js';
import styles from './FileTransfer.module.scss';
import { GeneralFieldWrapper, InputWrapper } from './input-wrappers.jsx';

const FileTransfer = ({ existingFiles, orderDetails, orderManagerStudioActionText, manualUploadActionText, loading, tabDescriptions = {}, onSendToStudio, onOrderManagerStudio, onSendToCustomer, onSendToVendor, onManualUpload, onReject }) => {
    const selectedFiles = useRef()
    const projectLanuage = Languages.find(({ id }) => id === orderDetails.project.language_id)?.cms_language_code

    const orderManagerStudioSendExistingFilesConfirm = (okText, existingFiles, onOk) => {
        Modal.confirm({
            title: 'Send files',
            icon: <ExclamationCircleOutlined />,
            width: 700,
            content: (
                <>
                    In addition to uploaded files, you have also selected {existingFiles.length} incoming file(s).<br />
                    <hr />
                    {existingFiles.map(file => (
                        <div className={styles.confirmFileList} key={file.url}>
                            <div className={styles.filePreview}>
                                <img src={getPreviewUrl(file.url, orderDetails.project_id)} />
                            </div>
                            <div className={styles.fileComment}>
                                <p className={styles.label}>{file.name}</p>
                            </div>
                        </div>
                    ))}
                </>
            ),
            onOk,
            okText
        });
    }

    const tabList = [
        {
            label: 'Send to Studio',
            key: 'sendToStudio',
            isVisible: !!onSendToStudio,
            children:
                <SendToStudio orderDetails={orderDetails} specification={orderDetails?.specification} description={tabDescriptions?.sendToStudio} onSubmit={(formData) => {
                    if (existingFiles.length !== 0 && (selectedFiles.current?.length === 0)) {
                        message.error('Please Select at least one file')
                        return
                    }
                    const _data = { ...Object.fromEntries(formData.entries()), existingFiles: selectedFiles.current }
                    onSendToStudio(_data)
                }} languageCode={projectLanuage} loading={loading} />
        },
        {
            label: 'Order Manager (Studio)',
            key: 'orderManager',
            isVisible: !!onOrderManagerStudio,
            children:
                <OrderManagerStudio
                    orderDetails={orderDetails}
                    specification={orderDetails?.specification}
                    description={tabDescriptions?.orderManager}
                    onSubmit={imageData => {
                        if (selectedFiles.current?.length > 0) {
                            orderManagerStudioSendExistingFilesConfirm(orderManagerStudioActionText, selectedFiles.current, () => {
                                onOrderManagerStudio({
                                    existingFiles: selectedFiles.current ?? [],
                                    newFiles: imageData
                                })
                            })
                        }
                        else {
                            onOrderManagerStudio({
                                existingFiles: selectedFiles.current ?? [],
                                newFiles: imageData
                            })
                        }
                    }}
                    actionText={orderManagerStudioActionText}
                    languageCode={projectLanuage}
                    loading={loading}
                />
        },
        {
            label: 'Send to Vendor',
            key: 'sendToVendor',
            isVisible: !!onSendToVendor,
            children: (
                <Form className='box--light-gray' onSubmit={async (e) => {
                    e.preventDefault()
                    if (!selectedFiles.current || selectedFiles.current.length === 0) {
                        message.error('Please Select at least one file')
                        return
                    }
                    const formData = new FormData(e.currentTarget)
                    const { comment } = Object.fromEntries(formData.entries())
                    onSendToVendor({
                        existingFiles: selectedFiles.current,
                        comment
                    })
                }}>
                    <div className="form-fields-wrapper--vertical">
                        {tabDescriptions?.sendToVendor}
                        <InputWrapper name="comment" label="Comment for Vendor" placeholder="Message for Vendor" allowClear />
                        <GeneralFieldWrapper style={{ alignItems: 'flex-start' }} horizontal>
                            <Button htmlType='submit' type="primary" loading={loading}>Send to Vendor</Button>
                        </GeneralFieldWrapper>
                    </div>
                </Form>
            )
        },
        {
            label: 'Send to Customer',
            key: 'sendToCustomer',
            isVisible: !!onSendToCustomer,
            children: (
                <Form className='box--light-gray' onSubmit={async (e) => {
                    e.preventDefault()
                    if (!selectedFiles.current || selectedFiles.current.length === 0) {
                        message.error('Please Select at least one file')
                        return
                    }
                    const formData = new FormData(e.currentTarget)
                    const { comment } = Object.fromEntries(formData.entries())
                    onSendToCustomer({
                        existingFiles: selectedFiles.current,
                        comment
                    })
                }}>
                    <div className="form-fields-wrapper--vertical">
                        {!!tabDescriptions?.sendToCustomer}
                        <InputWrapper name="comment" label="Comment for Customer" placeholder="Message for Customer" allowClear />
                        <GeneralFieldWrapper style={{ alignItems: 'flex-start' }} horizontal>
                            <Button htmlType='submit' type="primary" loading={loading}>Send to customer</Button>
                        </GeneralFieldWrapper>
                    </div>
                </Form>
            )
        },
        {
            label: manualUploadActionText,
            key: 'manualUpload',
            isVisible: !!onManualUpload,
            children:
                <OrderManagerStudio
                    orderDetails={orderDetails}
                    specification={orderDetails?.specification}
                    description={tabDescriptions.manualUpload}
                    onSubmit={imageData => (
                        onManualUpload({
                            existingFiles: selectedFiles.current ?? [],
                            newFiles: imageData
                        })
                    )}
                    actionText={manualUploadActionText}
                    languageCode={projectLanuage}
                    loading={loading}
                />
        },
        {
            label: 'Reject',
            key: 'reject',
            isVisible: !!onReject,
            children: (
                <Form className='box--light-gray' onSubmit={async (e) => {
                    e.preventDefault()
                    const formData = new FormData(e.currentTarget)
                    const { comment } = Object.fromEntries(formData.entries())
                    onReject({
                        comment
                    })
                }}>
                    <div className="form-fields-wrapper--vertical">
                        {tabDescriptions?.reject}
                        <InputWrapper name="comment" label="Reason for Rejection" placeholder="Specify reason for rejection" allowClear />
                        <GeneralFieldWrapper style={{ alignItems: 'flex-start' }} horizontal>
                            <Button htmlType='submit' type="primary" loading={loading}>Reject</Button>
                        </GeneralFieldWrapper>
                    </div>
                </Form>
            )
        },
    ]

    const activeTabs = tabList.filter(({ isVisible }) => !!isVisible)

    return (
        <>
            {!!existingFiles && existingFiles.length > 0 && <ArtworkSelector ref={selectedFiles} artworks={existingFiles} project_id={orderDetails.project_id} />}
            <Tabs list={activeTabs}></Tabs>
        </>
    )
}

FileTransfer.propTypes = {
    existingFiles: PropTypes.arrayOf(PropTypes.object),
    orderDetails: PropTypes.object.isRequired,
    orderManagerStudioActionText: PropTypes.string,
    manualUploadActionText: PropTypes.string,
    loading: PropTypes.bool,
    tabDescriptions: PropTypes.object,
    onSendToStudio: PropTypes.func,
    onOrderManagerStudio: PropTypes.func,
    onSendToCustomer: PropTypes.func,
    onSendToVendor: PropTypes.func,
    onManualUpload: PropTypes.func,
    onReject: PropTypes.func
    // stateChanger :PropTypes.func
}
export default FileTransfer