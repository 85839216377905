import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Form, Input, InputNumber, Modal, Radio, Rate } from 'antd';
import { debounce, isNil, omitBy } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useFetcher, useLoaderData } from 'react-router-dom';
import { defaultProductMargin, generateRouteUrl } from '../../../library/constants';
// import { Server } from '../../../library/constants/server';
import { getVendorDetails } from '../../../api/vendor/vendor';
import { ItemPriceVendorQuotationStatus, getVendorQuotationStatus } from '../../../library/constants/VendorItemPriceStatuses.js';
import { ERP_CURRENCY } from '../../../library/constants/dynamic.js';
import { globalDebounce } from '../../../library/helpers/global-debounce.js';
import { convertDataTypes } from '../../../library/helpers/index.js';
import { roundOfNumber } from '../../../library/utilities/intl.js';
import { calculateSelllingMarginPercent } from '../../../library/utilities/math.js';
import { SmallSection } from '../../design';
import { Currency, CurrencyConversion, Percent } from '../../inputs';
import Confirm from '../../message/Confirm.jsx';
import styles from './vendor-item-prices.module.scss';
import './vendor-item-prices.scss';

const calculateSellingPrice = (quantity, values) => {
    const { purchase_price = 0.00, finisher_price = 0.00, handling_charge = 0.00, shipping_charge = 0.00, margin = 0 } = values
    const totalCost = (purchase_price + finisher_price + (handling_charge + shipping_charge) / quantity)
    return roundOfNumber((totalCost / (1 - margin / 100)), 2)
}
const calculateMargin = (values, customPrice, quantity) => {
    const { purchase_price = 0.00, finisher_price = 0.00, handling_charge = 0.00, shipping_charge = 0.00 } = values
    // const totalPrice = ((purchase_price * quantity) + finisher_price + handling_charge + shipping_charge)
    const totalCost = (purchase_price + finisher_price + (handling_charge + shipping_charge) / quantity)
    return calculateSelllingMarginPercent(customPrice, totalCost)
}

const VendorQuotationForm = ({ vendor, ...otherProps }) => {
    const fetcher = useFetcher()

    return (
        <Confirm
            type='primary'
            title="Send Quotation to Vendor"
            onConfirm={() => {
                fetcher.submit({
                    action: 'sendQuoationRequest',
                    vendorId: vendor.id
                }, {
                    method: 'post',
                })
            }}
            content={
                <strong>Are you Sure You want to send request to vendor for quotaiton ?</strong>
            }
            {...otherProps}
            loading={fetcher.state !== 'idle'}
        >
            {vendor.quotationAssignment.vendor_quotation_status >= ItemPriceVendorQuotationStatus.VENDOR_QUOTATION_SENT ? 'Resend Request' : 'Send Request'}
        </Confirm>
    )
}
VendorQuotationForm.propTypes = {
    vendor: PropTypes.object.isRequired,
}

const VendorItemQuantityPriceRpw = ({ quantity, initialValues = {}, vendorCurrencyCode, projectCurrencyCode, onUpdate, requireFinisher, finisherCurrencyCode, canUpdateQuotation, websitePricesAvailable, vendorPricesAvailable, disabled }) => {
    const [form] = Form.useForm()
    const customSellingPriceValue = Form.useWatch('custom_selling_price_unit', form)

    const priceColumnsCount = 9 + (requireFinisher * 1)

    //Trying to prevent price updates from API while user is editing the prices\
    const updatePricesFromServer = debounce((values) => {
        form.setFieldsValue(values)
    }, 2000)

    const debouncedUpdateHandler = useCallback(globalDebounce(values => onUpdate(values), 1000), [quantity])

    const parsedInitialValues = useMemo(() => {
        // let values = Object.fromEntries(Object.entries(initialValues).map(([i, v]) => [i, Number(v)]))
        // if (vendorCurrencyDiffers) {
        //     values.purchase_price = values.purchase_price / vendorCurrencyRate
        //     values.handling_charge = values.handling_charge / vendorCurrencyRate
        //     values.shipping_charge = values.shipping_charge / vendorCurrencyRate
        // }
        return convertDataTypes(initialValues, {
            custom_selling_price_unit: parseFloat,
            finisher_price: parseFloat,
            handling_charge: parseFloat,
            margin: parseFloat,
            purchase_price: parseFloat,
            quantity: parseInt,
            quotation_price_reference: parseInt,
            sales_price: parseFloat,
            shipping_charge: parseFloat,
            status: parseInt,
            total_price: parseFloat,
            total_price_incl_vat: parseFloat,
            vat: parseFloat,
            vat_rate: parseFloat,
        })
        // return values
    }, [initialValues, vendorCurrencyCode, projectCurrencyCode])

    useEffect(() => {
        // if (parsedInitialValues.quotation_price_reference !== form.getFieldValue('quotation_price_reference')) {
        //     form.setFieldValue('quoparsedInitialValuestation_price_reference', parsedInitialValues.quotation_price_reference)
        // }
        // if (parsedInitialValues.purchase_price !== form.getFieldValue('purchase_price')) {
        //     form.setFieldValue('purchase_price', parsedInitialValues.purchase_price)
        // }
        // if (parsedInitialValues.shipping_charge !== form.getFieldValue('shipping_charge')) {
        //     form.setFieldValue('shipping_charge', parsedInitialValues.shipping_charge)
        // }
        //Prevent auto updating if using sharepoint/custom prices
        form.setFieldValue('quotation_price_reference', parsedInitialValues.quotation_price_reference)
        // if (parsedInitialValues.quotation_price_reference !== 2) {
        //form.setFieldsValue(parsedInitialValues)
        // }
        updatePricesFromServer(parsedInitialValues)
    }, [initialValues])

    const vendorCurrencyDiffers = vendorCurrencyCode !== ERP_CURRENCY
    const projectCurrencyDiffers = projectCurrencyCode !== ERP_CURRENCY
    const finisherCurrencyDiffers = finisherCurrencyCode && finisherCurrencyCode !== ERP_CURRENCY
    const vendorCurrencyRate = initialValues?.conversion_rate?.[vendorCurrencyCode]
    const projectCurrencyRate = initialValues?.conversion_rate?.[projectCurrencyCode]
    const finisherCurrencyRate = finisherCurrencyCode && initialValues?.conversion_rate?.[finisherCurrencyCode]
    const canUpdateCosts = true

    const handleValueChange = (changedFields, values) => {
        const fieldName = Object.keys(changedFields)[0];
        const updatedValues = omitBy(values, isNil)
        if (canUpdateQuotation) {
            if (fieldName === 'custom_selling_price_unit') {
                //Update selling price directly
                const _margin = calculateMargin(values, changedFields[fieldName], quantity)
                form.setFieldValue('margin', _margin)
                updatedValues.margin = _margin
            }
            else {
                const _customSellingPrice = calculateSellingPrice(quantity, values)
                form.setFieldValue('custom_selling_price_unit', _customSellingPrice)
                // form.setFieldValue('custom_selling_price_unit', formatCurrencyField(_customSellingPrice, projectCurrencyCode))
                updatedValues.custom_selling_price_unit = _customSellingPrice
            }
            const total_price = updatedValues.custom_selling_price_unit * quantity
            const vat_amount = total_price * (parsedInitialValues.vat_rate / 100)
            form.setFieldValue('total_price_incl_vat', total_price + vat_amount)
        }
        else {
            //Adjust margin to keep the sales price same
            const _margin = calculateMargin(values, parsedInitialValues.custom_selling_price_unit, quantity)
            form.setFieldValue('margin', _margin)
            updatedValues.margin = _margin
        }
        updatePricesFromServer.cancel()
        debouncedUpdateHandler(updatedValues)
    }

    return (
        <Form form={form} initialValues={{ ...parsedInitialValues }} name={`vendor-price-item-${quantity}`} onValuesChange={handleValueChange}>
            <div className={`${requireFinisher ? styles['vendorPriceRow--requireFinisher'] : styles.vendorPriceRow} vendor-price-row`} style={{ '--columns': priceColumnsCount }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    <Form.Item>
                        <InputNumber value={quantity} disabled controls={false} />
                    </Form.Item>
                    {vendorCurrencyDiffers && (
                        <div>Conversion = x {roundOfNumber(vendorCurrencyRate, 3)}</div>
                    )}
                </div>
                <Form.Item name="purchase_price">
                    {vendorCurrencyDiffers ?
                        <CurrencyConversion currency={ERP_CURRENCY} secondCurrency={vendorCurrencyCode} conversionRate={vendorCurrencyRate} precision={3} controls={false} disabled={disabled || !canUpdateCosts} /> :
                        <Currency currency={ERP_CURRENCY} precision={3} controls={false} disabled={disabled || !canUpdateCosts} />
                    }
                </Form.Item>
                {requireFinisher === true ? (
                    <Form.Item name="finisher_price">
                        {finisherCurrencyDiffers ?
                            <CurrencyConversion currency={ERP_CURRENCY} secondCurrency={finisherCurrencyCode} conversionRate={finisherCurrencyRate} precision={3} controls={false} disabled={disabled || !canUpdateCosts} /> :
                            <Currency currency={ERP_CURRENCY} precision={3} controls={false} disabled={disabled || !canUpdateCosts} />
                        }
                    </Form.Item>
                ) : <div style={{ display: 'none' }}></div>}
                <Form.Item name="handling_charge">
                    <Currency currency={ERP_CURRENCY} precision={3} controls={false} disabled={disabled || !canUpdateCosts} />
                    {/* {vendorCurrencyDiffers ?
                        <CurrencyConversion currency={ERP_CURRENCY} secondCurrency={vendorCurrencyCode} conversionRate={vendorCurrencyRate} precision={3}  controls={false} /> :
                    } */}
                </Form.Item>
                <Form.Item name="shipping_charge">
                    {vendorCurrencyDiffers ?
                        <CurrencyConversion currency={ERP_CURRENCY} secondCurrency={vendorCurrencyCode} conversionRate={vendorCurrencyRate} precision={3} controls={false} disabled={disabled || !canUpdateCosts} /> :
                        <Currency currency={ERP_CURRENCY} precision={3} controls={false} disabled={disabled || !canUpdateCosts} />
                    }
                </Form.Item>
                <Form.Item name="margin">
                    <Percent type='percent' controls={false} disabled={disabled || !canUpdateQuotation} />
                </Form.Item>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    <Form.Item name="custom_selling_price_unit">
                        <Currency currency={ERP_CURRENCY} controls={false} disabled={disabled || !canUpdateQuotation} />
                    </Form.Item>
                    {projectCurrencyDiffers && (
                        <div>Conversion = x {roundOfNumber(projectCurrencyRate, 3)}</div>
                    )}
                </div>
                <Form.Item >
                    {projectCurrencyDiffers ?
                        <CurrencyConversion currency={ERP_CURRENCY} value={customSellingPriceValue} secondCurrency={projectCurrencyCode} conversionRate={projectCurrencyRate} disabled controls={false} /> :
                        <Currency currency={ERP_CURRENCY} value={customSellingPriceValue} disabled controls={false} />
                    }
                </Form.Item>
                <Form.Item >
                    {projectCurrencyDiffers ?
                        <CurrencyConversion currency={ERP_CURRENCY} value={customSellingPriceValue * quantity} secondCurrency={projectCurrencyCode} conversionRate={projectCurrencyRate} onChange={val => {
                            form.setFieldValue('custom_selling_price_unit', val / quantity)
                            handleValueChange({ custom_selling_price_unit: val / quantity }, form.getFieldsValue())
                        }} disabled={disabled || !canUpdateQuotation} /> :
                        <Currency currency={ERP_CURRENCY} value={customSellingPriceValue * quantity} onChange={val => {
                            form.setFieldValue('custom_selling_price_unit', val / quantity)
                            handleValueChange({ custom_selling_price_unit: val / quantity }, form.getFieldsValue())
                        }} disabled={disabled || !canUpdateQuotation} />
                    }
                </Form.Item>
                <Form.Item name="total_price_incl_vat">
                    {projectCurrencyDiffers ?
                        <CurrencyConversion currency={ERP_CURRENCY} secondCurrency={projectCurrencyCode} conversionRate={projectCurrencyRate} controls={false} disabled /> :
                        <Currency currency={ERP_CURRENCY} controls={false} disabled />
                    }
                </Form.Item>
            </div>
            <Form.Item name='quotation_price_reference' label="Quoation Reference" className={styles.quotationReference} shouldUpdate>
                <Radio.Group optionType="button" buttonStyle="solid" disabled={disabled} >
                    <Radio value={1} disabled={!websitePricesAvailable}>Website</Radio>
                    <Radio value={2}>Share point</Radio>
                    <Radio value={3} disabled={!vendorPricesAvailable}>Prices from Vendor</Radio>
                </Radio.Group>
            </Form.Item>
        </Form >
    )
}

VendorItemQuantityPriceRpw.propTypes = {
    quantity: PropTypes.number.isRequired,
    initialValues: PropTypes.object,
    onUpdate: PropTypes.func.isRequired,
    requireFinisher: PropTypes.bool,
    vendorCurrencyCode: PropTypes.oneOf(['EUR', 'GBP', 'USD']).isRequired,
    projectCurrencyCode: PropTypes.oneOf(['EUR', 'GBP', 'USD']).isRequired,
    finisherCurrencyCode: PropTypes.oneOf(['EUR', 'GBP', 'USD']),
    canUpdateQuotation: PropTypes.bool,
    websitePricesAvailable: PropTypes.bool,
    vendorPricesAvailable: PropTypes.bool,
    disabled: PropTypes.bool,
}

const VendorItemPrices = ({ project_id, finisher, vendor, quantities, children, onVendorSelectionChange, onPriceUpdate, onVendorCommentChange, item, canUpdateQuotation = true, disabled }) => {
    const { currency, itemDetails } = useLoaderData()
    const [vendorSelected, setVendorSelected] = useState(!!vendor.quotationAssignment)
    const [VendorData, setVendornData] = useState(null)
    const [isVendorModal, setIsVendorModalOpen] = useState(false);

    useEffect(() => {
        setVendorSelected(!!vendor.quotationAssignment)
    }, [vendor])

    const requireFinisher = !!finisher?.id //Finisher is involved
    const priceColumnsCount = 9 + (requireFinisher * 1)
    const vendorQuotationButtonText = () => {
        switch (vendor.quotationAssignment.vendor_quotation_status) {
            case ItemPriceVendorQuotationStatus.VENDOR_QUOTATION_SENT:
            case ItemPriceVendorQuotationStatus.VISUAL_SENT:
                return "View Sent Quotation"
            case ItemPriceVendorQuotationStatus.VENDOR_QUOTATION_RECEIVED:
                return "View Quotation"
            case ItemPriceVendorQuotationStatus.VENDOR_REQUESTED_VISUAL:
                return "Send Visual to Vendor"
            default:
                return ""
        }
    }

    const showVendorDetailsModal = async (id) => {
        const v = await getVendorDetails(id)
        setVendornData(v)
        setIsVendorModalOpen(true);
    };

    const handleVendorSelectionChange = (checked) => {
        setVendorSelected(checked)
        onVendorSelectionChange(vendor.id, checked)
    }

    return (
        <div className={styles.container}>
            <SmallSection colorDark="#f5c53e" colorLight="#fef7e7">
                {!quantities.length && <Alert message="Please select atleast one quantity" type="warning" showIcon />}
                <table className={styles.vendorDetails}>
                    <colgroup>
                        <col width="50px" />
                    </colgroup>
                    <thead>
                        <tr>
                            <td></td>
                            <td>Vendor</td>
                            <td>Price List</td>
                            <td>Price List Link</td>
                            <td>Rating</td>
                            <td>Status</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>
                                <Checkbox
                                    key={vendor.quotationAssignment?.id} //Forces rerender
                                    disabled={disabled || quantities.includes(0) || !quantities.length}
                                    checked={vendorSelected}
                                    onChange={(e) => {
                                        if (!e.target.checked) {
                                            Modal.confirm({
                                                content: 'The will remove the vendor prices.Do you really want to continue ?',
                                                onOk() {
                                                    handleVendorSelectionChange(e.target.checked)
                                                }
                                            })
                                        }
                                        else {
                                            handleVendorSelectionChange(e.target.checked)
                                        }

                                    }}
                                />
                            </th>
                            <th>
                                <Link to={generateRouteUrl('VendorUpdate', { id: vendor.id })}>{vendor.company}</Link>
                                {<InfoCircleOutlined onClick={() => {
                                    showVendorDetailsModal(vendor.id)
                                }} />}
                                <Modal title={VendorData?.company} onCancel={() => { setIsVendorModalOpen(false) }} open={isVendorModal} footer={null}>
                                    <div className={styles.vendorInfo}>
                                        <p>Contact No.  :{VendorData?.mobile_number}</p>
                                        <p>Email : {VendorData?.email}</p>
                                        <p>No of projects done : {VendorData?.completed_project} </p>
                                    </div>
                                </Modal>
                            </th>
                            <th>{vendor.cms_vendor_info?.vendor_price_link ? 'Available' : 'Unavailable'}</th>
                            <th>{vendor.cms_vendor_info?.vendor_price_link && (
                                <Button href={vendor.cms_vendor_info.vendor_price_link} type="link" target="_blank">View</Button>
                            )}</th>
                            <th><Rate defaultValue={vendor.rating ?? 0} disabled /></th>
                            <th>{vendor.quotationAssignment?.vendor_quotation_status && getVendorQuotationStatus(vendor.quotationAssignment.vendor_quotation_status)}</th>
                        </tr>
                    </tbody>
                </table>
            </SmallSection>
            {!!vendor.quotationAssignment && (
                <div>
                    {quantities.length > 0 && (
                        <div className={`${requireFinisher ? styles['quantityPricesHead--requireFinisher'] : styles.quantityPricesHead} vendor-price-row`} style={{ '--columns': priceColumnsCount }}>
                            <div>Quantity</div>
                            <div>Purchase Price</div>
                            {requireFinisher === true ? <div>Finisher&apos;s Price</div> : <div style={{ display: 'none' }}></div>}
                            <div>Handling Charges</div>
                            <div>Shipping Charges</div>
                            <div>Margin</div>
                            <div>Custom Selling Price/Unit</div>
                            <div>Sales Price </div>
                            <div>Total Price</div>
                            <div>Total Price (Incl. VAT)</div>
                        </div>
                    )}
                    {quantities.map(quantity => (
                        <VendorItemQuantityPriceRpw
                            key={quantity}
                            requireFinisher={requireFinisher}
                            finisherCurrencyCode={requireFinisher ? finisher.currency?.code : null}
                            quantity={quantity}
                            initialValues={
                                vendor.prices.find(p => p.quantity === quantity) ?? {
                                    margin: defaultProductMargin,
                                    conversion_rate: itemDetails.conversion_rate
                                }
                            }
                            onUpdate={(prices) => {
                                onPriceUpdate(vendor.id, quantity, prices)
                            }}
                            vendorCurrencyCode={vendor.currency.code}
                            projectCurrencyCode={currency.code}
                            canUpdateQuotation={canUpdateQuotation}
                            websitePricesAvailable={!!vendor.cms_vendor_info?.price_id}
                            vendorPricesAvailable={vendor.quotationAssignment.vendor_quotation_status >= ItemPriceVendorQuotationStatus.VENDOR_QUOTATION_SENT}
                            disabled={disabled || (itemDetails.approved_quantity && quantity !== itemDetails.approved_quantity)}
                        />
                    ))}
                    <Form layout="vertical" initialValues={vendor.quotationAssignment} onValuesChange={(_, values) => onVendorCommentChange(vendor.id, values)}>
                        <Form.Item name="comment" label="Special Comment for Vendor">
                            <Input.TextArea disabled={disabled} />
                        </Form.Item>
                    </Form>
                    <div className="actions align--left">
                        <VendorQuotationForm vendor={vendor} disabled={disabled} />
                        {vendor.quotationAssignment.vendor_quotation_status >= ItemPriceVendorQuotationStatus.VENDOR_QUOTATION_SENT && (
                            <Link to={generateRouteUrl('VendorQuotation', {
                                projectId: project_id,
                                itemId: item,
                                vendorId: vendor.id
                            })}>
                                <Button>{vendorQuotationButtonText()}</Button>
                            </Link>
                        )}
                    </div>
                </div>
            )}
            {children}
            <Outlet />
        </div>
    )
}
VendorItemPrices.propTypes = {
    project_id: PropTypes.number,
    vendor: PropTypes.object.isRequired,
    item: PropTypes.number,
    selectedVendorId: PropTypes.number,
    quantities: PropTypes.arrayOf(Number).isRequired,
    onVendorSelectionChange: PropTypes.func.isRequired,
    onPriceUpdate: PropTypes.func.isRequired,
    onVendorCommentChange: PropTypes.func.isRequired,
    children: PropTypes.node,
    finisher: PropTypes.object,
    canUpdateQuotation: PropTypes.bool,
    disabled: PropTypes.bool,
}
export default VendorItemPrices