import { PlusOutlined, SearchOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Input, Space, Drawer } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { generatePath, useLoaderData, useNavigate } from 'react-router-dom';
import { getAllVendors } from '../../api/vendor/vendor';
import { Page } from '../../components/page';
import { Table } from '../../components/table';
import { useFetch } from '../../hooks';
import { VendorColumns, generateRouteUrl } from '../../library/constants/index.js';
import { searchParamsToObject } from '../../library/helpers/index.js';
import { SelectedFilters } from '../../components/data/selected-filters.jsx';
import VendorFilterForm from '../../components/form/vendor-filter/vendor-filter.jsx';
// import { VendorDetails } from '../../components/form';

const FILTER_NAMES = {
	country_id: "Country",
	language_id: "Language",
	currency_id: "Currency",
	using_portal: "Portal",
	discount: "Discount",
	vendor_type: "Vendor type",
	product_id: "Product"
}

function VendorListView(props) {
	const initialData = useLoaderData()
	const navigate = useNavigate()
	const [filtersVisible, setFiltersVisible] = useState(false)
	const [list, hasMore, isLoading, searchParamsParsed, {
		setSort,
		setFilters,
		loadMore
	}] = useFetch({ initialData })

	const handleRowClick = (_, record) => {
		navigate(generatePath('/vendors/:id', {
			id: record.id
		}))
	}

	return (
		<Page className='vendor-list' backLink={false} title={props.title} summary={initialData?.meta?.totalCount ? `(${initialData?.meta?.totalCount} Records)` : '(No Records)'} header={
			<>
				<div className=""></div>
				<Space.Compact style={{ width: '100%', maxWidth: '400px' }}>
					<Input.Search placeholder="Search" defaultValue={searchParamsParsed?.filter?.search} prefix={<SearchOutlined />} onChange={(e) => setFilters({ 'search': e.target.value })} allowClear />
					<Button onClick={() => setFiltersVisible(v => !v)} icon={<FilterOutlined />}>Filters</Button>
				</Space.Compact>
				<Button type="primary" onClick={() => { navigate(generateRouteUrl('VendorCreate')) }} icon={<PlusOutlined />}>Add new Vendor</Button>
			</>
		} subHeader={initialData.meta?.filters && <SelectedFilters filters={initialData.meta.filters} filterNames={FILTER_NAMES} updateFilters={setFilters} />} >
			<Drawer open={filtersVisible} onClose={() => setFiltersVisible(false)} >
				<VendorFilterForm
					key={JSON.stringify(searchParamsParsed.filter)}
					data={searchParamsParsed.filter}
					onFinish={(filters) => {
						setFilters(filters);
						setFiltersVisible(false); // Close the Drawer after applying filters
					}}
				/>
			</Drawer>
			<Table
				columns={VendorColumns}
				data={list}
				/* perPage={Number(meta?.limit)} currentPage={Number(meta?.page)} total={Number(meta?.totalCount)} */
				isLoading={isLoading}
				onChange={setSort}
				onRowClick={handleRowClick}
				hasMore={hasMore}
				loadMore={loadMore}
			/>
		</Page>
	)
}


VendorListView.Loader = async ({ request }) => {
	const url = new URL(request.url)
	const { filter, page, sort } = searchParamsToObject(url.searchParams)
	const { data, meta } = await getAllVendors({ filter, page, ...sort })
	return { data, meta }
}

VendorListView.propTypes = {
	title: PropTypes.string,
}

export default VendorListView
