import { Button, Drawer, Input, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Form, useActionData, useLoaderData, useNavigate } from 'react-router-dom';
import { getGeneralLedgerDetails, updateGeneralLedger } from '../../api/settings/general-ledger.js';
import ExecutiveEntitySelect from '../../components/inputs/ExecutiveEntitySelect.js';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { GeneralLedgerCategories, VatCategories } from '../../library/constants';
import { VatTypes } from '../../library/constants/dynamic.js';
import { getFieldErrors, getFieldStatus } from '../../library/helpers/forms/index.js';
import { convertDataTypes } from '../../library/helpers/index.js';
import Styles from '../users/user-details.module.scss';
import { FieldWrapper } from '../../components/inputs/input-wrappers.jsx';
const { Option } = Select;

const LedgerDetails = () => {
    const { ledgerDetails } = useLoaderData()
    const navigate = useNavigate()
    const { errors = null } = useActionData() || {}
    const [vatId, setVatId] = useState(ledgerDetails.vat_type_id)
    const [entity, setEntity] = useState(ledgerDetails.entity_id || '');
    const [category, setCategory] = useState(ledgerDetails.category || '');
    const [vatCategory, setVatCategory] = useState(ledgerDetails.vat_category || '')

    useEffect(() => {
        if (ledgerDetails) {
            setEntity(ledgerDetails.entity_id || '')
            setCategory(ledgerDetails.category || '')
            setVatCategory(ledgerDetails.vat_category || '')
        }
    }, [ledgerDetails])

    return (
        <Drawer open={true} mask={false} title={`Ledger: ${ledgerDetails.name}`} onClose={() => navigate('..', { replace: true, relative: 'route' })} >
            <Form method="post" className={Styles.formWrapper}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <FieldWrapper label="Name" showRequired>
                            <Input
                                type="text"
                                name="name"
                                key={ledgerDetails.name}
                                defaultValue={ledgerDetails.name}
                                required
                                status={getFieldStatus('name', errors)}
                            />
                        </FieldWrapper>
                        {getFieldErrors('name', errors)}
                    </div>
                </div>

                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <FieldWrapper label="Entity" showRequired>
                            <ExecutiveEntitySelect
                                onChange={(val) => setEntity(val)}
                                value={entity}
                                required
                                status={getFieldStatus('entity_id', errors)}
                            />
                        </FieldWrapper>
                        <input type="hidden" name="entity_id" value={entity || ''} />
                        {getFieldErrors('entity_id', errors)}
                    </div>
                </div>

                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <FieldWrapper label="Category" showRequired>
                            <Select
                                name="category"
                                defaultValue={category}
                                value={category}
                                required
                                status={getFieldStatus('category', errors)}
                                onChange={(c) => setCategory(c)}
                            >
                                {Object.entries(GeneralLedgerCategories).map(([value, label]) => (
                                    <Option key={value} value={Number(value)}>
                                        {label}
                                    </Option>
                                ))}
                            </Select>
                        </FieldWrapper>
                        <input type="hidden" name="category" value={category || ''} />
                        {getFieldErrors('category', errors)}
                    </div>
                </div>

                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <FieldWrapper label="VAT Category" showRequired>
                            <Select
                                name="vat_category"
                                defaultValue={vatCategory}
                                required
                                status={getFieldStatus('vat_category', errors)}
                                onChange={(c) => setVatCategory(c)}
                            >
                                {Object.entries(VatCategories).map(([value, label]) => (
                                    <Option key={value} value={Number(value)}>
                                        {label}
                                    </Option>
                                ))}
                            </Select>
                        </FieldWrapper>
                        <input type="hidden" name="vat_category" value={vatCategory || ''} />
                        {getFieldErrors('vat_category', errors)}
                    </div>
                </div>

                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <FieldWrapper label="VAT Type" showRequired>
                            <Select
                                options={VatTypes.filter(({ entity_id }) => entity_id === entity)}
                                fieldNames={{ value: 'id', label: 'description' }}
                                defaultValue={vatId}
                                onChange={(c) => setVatId(c)}
                                status={getFieldStatus('vat_type_id', errors)}
                            />
                        </FieldWrapper>
                        <input type="hidden" name="vat_type_id" value={vatId || ''} />
                        {getFieldErrors('vat_type_id', errors)}
                    </div>
                </div>

                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <FieldWrapper label="Ledger Text">
                            <Input
                                type="text"
                                name="ledger_text"
                                key={ledgerDetails.ledger_text}
                                defaultValue={ledgerDetails.ledger_text}
                                status={getFieldStatus('ledger_text', errors)}
                            />
                        </FieldWrapper>
                        {getFieldErrors('ledger_text', errors)}
                    </div>
                </div>

                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <FieldWrapper label="Ledger Text En">
                            <Input
                                type="text"
                                name="ledger_text_en"
                                key={ledgerDetails.ledger_text_en}
                                defaultValue={ledgerDetails.ledger_text_en}
                                status={getFieldStatus('ledger_text_en', errors)}
                            />
                        </FieldWrapper>
                        {getFieldErrors('ledger_text_en', errors)}
                    </div>
                </div>

                <Button type="primary" name="action" value="updateLedger" htmlType="submit">
                    Update
                </Button>
            </Form>
        </Drawer >
    )
}

LedgerDetails.Actions = {
    "updateLedger": async ({ params, data }) => {
        const { id } = params
        try {
            const payload = convertDataTypes(data, {
                entity_id: v => (parseInt(v) || undefined),
                vat_type_id: parseInt,
                vat_category: parseInt,
                category: parseInt,
            })
            const msg = await updateGeneralLedger(id, payload)
            message.success(msg)
            return true
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false
    },
}

LedgerDetails.Loader = async ({ params }) => {
    const { id } = params
    const ledgerDetails = await getGeneralLedgerDetails(id)
    return { ledgerDetails }
}

export default LedgerDetails







