import {
    FilePdfOutlined
} from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { getInvoicePreviewURL } from '../../../api/finance/invoice.js';
import { findInvoiceStatusById, getFormattedId } from '../../helpers/index.js';
import { textToClassName } from '../../utilities/common.js';
import { formatDate, formatDateTime, formatNumber, formatPrice, getTimeAgo } from '../../utilities/intl.js';
import { getFilePath } from '../../utilities/urls.js';
import { FinanceProjectStatusDetails } from '../finance-project-statuses.js';
import { Server } from '../server';
import { fetchSmallWebsiteLogo } from '../../../components/design/getWebsiteLogo.js';
import { ProjectStatusDetailsAuditTrail } from '../kanabanColumns/projecKanabanColumns.js';

export const FinanceProjectColumns = [
    {
        title: 'Project Id',
        dataIndex: 'id',
        sorter: true,
        fixed: 'left',
        render: id => getFormattedId(id)
    },
    {
        title: 'Status',
        dataIndex: 'finance_status',
        sorter: true,
        //render: status => FinanceProjectStatusDetails[status] ?? ''
        render: (status) => {
            status = FinanceProjectStatusDetails[status] ?? ''
            return <div className={`status-tag status-tag--${textToClassName(status)}`}>{status}</div>
        },
    },
    {
        title: 'Relation',
        dataIndex: 'customer',
        render: (customer) => {
            return fetchSmallWebsiteLogo(customer.website, customer.company)
        }
        //sorter: true,
    },
    {
        title: 'Notes from Sales',
        dataIndex: 'internal_comment',
        //sorter: true,
    },
    {
        title: 'Language',
        dataIndex: 'language',
        //sorter: true,
        render: language => language.name
    },
    {
        title: 'Country',
        dataIndex: ['customer', 'country'],
        ///sorter: true,
        render: (country) => {
            return <img src={`${Server.BaseUrl}/${country.flag_url}`} alt={country.name} title={country.name} />
        }
    },
    {
        title: 'Invoiced %',
        dataIndex: 'invoiced_percentage',
        render: val => formatNumber(val, 'en')
    },
]

export const getFinanceProjectInvoiceColumns = (projectId, currencyCode) => [
    {
        title: 'Invoice No.',
        dataIndex: 'invoice_number'
    },
    {
        title: 'Payment Notes',
        dataIndex: 'payment_notes',
    },
    {
        title: 'Invoice Date',
        dataIndex: 'invoice_date',
        render: (date) => formatDate(date),
    },
    {
        title: 'Excl. VAT',
        dataIndex: 'total_price_excl_vat_converted',
        render: (price) => formatPrice(price[currencyCode], currencyCode),
    },
    {
        title: 'VAT',
        dataIndex: 'total_vat_converted',
        render: (price) => formatPrice(price[currencyCode], currencyCode),
    },
    {
        title: 'Incl. VAT',
        dataIndex: 'total_price_incl_vat_converted',
        render: (price) => formatPrice(price[currencyCode], currencyCode),
    },
    {
        title: 'VAT Type',
        dataIndex: 'vat_type',
    },
    {
        title: 'Payment Status',
        dataIndex: 'status',
        render: (status) => findInvoiceStatusById(status),
    },
    {
        title: 'PDF',
        dataIndex: 'file',
        render: (file, data) => (
            file ?
                <Link to={getFilePath(file, projectId)} reloadDocument><FilePdfOutlined /> Invoice</Link> :
                <a href={getInvoicePreviewURL(data.id)} target="_blank" rel="noreferrer" download><FilePdfOutlined /> Invoice</a>
        )
    },
]

export const FinanceProjectAuditTrailColumns = [
    {
        title: 'Date',
        dataIndex: 'createdAt',
        render: formatDateTime
    },
    // {
    //     title: 'Status',
    //     dataIndex: 'status',
    //     render: (status) => FinanceProjectStatusDetails[status] ?? '',
    // },
    {
        title: 'User',
        dataIndex: 'user',
        render: user => user?.name,

    },
    {
        title: 'Message',
        dataIndex: 'message',
    },
]

export const ProjectAuditTrailColumns = [
    {
        title: 'Date',
        dataIndex: 'createdAt',
        render: getTimeAgo
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: (status) => ProjectStatusDetailsAuditTrail[status] ?? '',
    },
    {
        title: 'User',
        dataIndex: 'user',
        render: user => user?.name,

    },
    {
        title: 'Message',
        dataIndex: 'message',
    },
]

export const ItemUpdateAuditTrailColumns = [
    {
        title: 'Date',
        dataIndex: 'createdAt',
        render: formatDateTime
    },
    {
        title: 'User',
        dataIndex: 'user',
        render: user => user?.name,

    },
    {
        title: 'Message',
        dataIndex: 'message',
    },
]