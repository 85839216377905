import { CheckOutlined } from '@ant-design/icons';
import { Button, Radio, Rate, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { VendorItemPriceStatuses } from '../../../library/constants/VendorItemPriceStatuses.js';
import { formatNumber } from '../../../library/utilities/intl.js';
import SmallSection from '../../design/small-section.jsx';
import styles from './project-item-vendor-prices.module.scss';

const formatePrice = (price) => {
    return (price ?? 0).toLocaleString('nl-Nl', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2
    });
}

const ProjectItemVendorPrices = ({ data, isFinisher, active, onVendorChange }) => {
    return <div key={data.id} className={styles.vendor}>
        <div className={styles.vendorHeader}>
            <div className={styles.vendorSelect}>
                {onVendorChange && <Radio checked={data.isSelected} onChange={() => onVendorChange(data.id)} />}
            </div>
            <div className="">
                <div className={styles.vendorName}>
                    {data.company}
                </div>
                <div className={styles.vendorRating}>
                    <Rate defaultValue={data?.rating} disabled />
                </div>
            </div>
            <div className={styles.vendorActions}>
                {(data.status === VendorItemPriceStatuses.USED_FOR_QUOTATION) && (
                    <Tooltip title="Selected for quotation">
                        <Button icon={<CheckOutlined />} shape="square" type="primary" />
                    </Tooltip>
                )}
                {(data.status === VendorItemPriceStatuses.SELECTED_FOR_PRODUCTION) && (
                    <Tooltip title="Selected for Production">
                        <Button icon={<CheckOutlined />} shape="square" type="primary" />
                    </Tooltip>
                )}
                {/* <Tooltip title="Expected Delivery Date by Vendor">
                    <Button shape="square" type="actionpanel" ghost>24-01-2023</Button>
                </Tooltip> */}
            </div>
        </div>
        <div className={styles.vendorPrices}>
            <div className={`${styles.quantityPricesHead}`}>
                <div>Quantity</div>
                <div>Purchase Price</div>
                {isFinisher === 1 && <div>Finisher&apos;s Price</div>}
                <div>Handling Charges</div>
                <div>Shipping Charges</div>
                <div>Margin</div>
                <div>Sales Price</div>
                <div>Total Price</div>
                <div>Total Price (Incl. VAT)</div>
            </div>
            {!!data.prices && data.prices.map((prices) => (
                <SmallSection key={prices.quantity} colorLight="#fff2e9" colorDark="#fe7b1c" containerStyles={{ opacity: (active === 1 && prices.status === 1) ? 0.6 : 1 }}>
                    <div className={styles.vendorQuantityPrice}>
                        <div>{prices.quantity}</div>
                        <div>{formatePrice(prices.purchase_price)}</div>
                        {isFinisher === 1 && ( <div>{formatePrice(prices.finisher_price)}</div>)}
                        <div>{formatePrice(prices.handling_charge)}</div>
                        <div>{formatePrice(prices.shipping_charge)}</div>
                        <div>{formatNumber(prices.margin)}%</div>
                        <div>{formatePrice(prices.sales_price)}</div>
                        <div>{formatePrice(prices.total_price)}</div>
                        <div>{formatePrice(prices.total_price_incl_vat)}</div>
                    </div>
                </SmallSection>
            ))}
        </div>
    </div>;
}

ProjectItemVendorPrices.propTypes = {
    data: PropTypes.object.isRequired,
    active: PropTypes.number.isRequired,
    onVendorChange: PropTypes.func,
    isFinisher: PropTypes.number,
}

export default ProjectItemVendorPrices