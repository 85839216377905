import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Drawer, Input, Select, message } from 'antd';
import React, { useState } from 'react';
import { Form, redirect, useActionData, useNavigate } from 'react-router-dom';
import { createUser } from '../../api/user';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { Permission, RolePermission, UserRoles } from '../../library/constants/dynamic.js';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { getFieldErrors, getFieldStatus } from '../../library/helpers/forms/index.js';
import Styles from './user-details.module.scss';
import { normalizeEmail } from '../../library/helpers/normalize.js';
import { FieldWrapper } from '../../components/inputs/input-wrappers.jsx';
const UserCreate = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [userType, setUserType] = useState([])
    const [permission, setPermission] = useState([])
    const { errors = null } = useActionData() || {}

    const handleRolesChange = (roles) => {
        // If the roles array is empty, reset permissions
        if (roles.length === 0) {
            setUserType([])
            setPermission([])
            return
        }

        let newPermissions = []
        setUserType(roles)
        roles.forEach(r => {
            const rolePermissions = RolePermission.find(({ id }) => r === id)?.value || []
            newPermissions = [...newPermissions, ...rolePermissions]
        })
        setPermission([...new Set(newPermissions)])
    }

    return (
        <Drawer open={true} width="720px" mask={false} title="Create User" onClose={() => navigate('..', { replace: true, relative: 'route' })} >
        <Form method="post" className={Styles.formWrapper}>
            <div className={Styles.fieldsWrapper}>
                <div className={Styles.inputField}>
                    <FieldWrapper label="Name" showRequired>
                        <Input type="text" name="name" required status={getFieldStatus('name', errors)} />
                    </FieldWrapper>
                    {getFieldErrors('name', errors)}
                </div>
            </div>
    
            <div className={Styles.fieldsWrapper}>
                <div className={Styles.inputField}>
                    <FieldWrapper label="Email" showRequired>
                        <Input type="email"
                            name="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(normalizeEmail(e.target.value))}
                            status={getFieldStatus('email', errors)} />
                    </FieldWrapper>
                    {getFieldErrors('email', errors)}
                </div>
            </div>
    
            <div className={Styles.fieldsWrapper}>
                <div className={Styles.inputField}>
                    <FieldWrapper label="Roles">
                        <Select
                            options={UserRoles}
                            fieldNames={{ value: 'id', label: 'name' }}
                            onChange={handleRolesChange}
                            mode='multiple'
                            status={getFieldStatus('type', errors)}
                        />
                    </FieldWrapper>
                    {getFieldErrors('type', errors)}
                    <input type="hidden" name="type" value={userType} />
                </div>
            </div>
    
            <div className={Styles.fieldsWrapper}>
                <div className={Styles.inputField}>
                    <FieldWrapper label="Permission">
                        <Select
                            options={Permission}
                            mode="multiple"
                            value={permission}
                            fieldNames={{ value: 'id', label: 'name' }}
                            defaultValue={permission}
                            onChange={c => setPermission(c)}
                            status={getFieldStatus('type', errors)}
                        />
                    </FieldWrapper>
                    {getFieldErrors('type', errors)}
                    <input type="hidden" name="permission" value={permission} />
                </div>
            </div>
    
            <div className={Styles.fieldsWrapper}>
                <div className={Styles.inputField}>
                    <FieldWrapper label="New Password" showRequired>
                        <Input.Password
                            placeholder="New Password"
                            name="password"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            status={getFieldStatus('name', errors)}
                            pattern="^(?=.*[0-9])(?=.*[\W_])(?=.*[a-zA-Z])(?=\S+$).{6,}$"
                            title="Password must contain at least one digit, one special character, one letter, and be at least 6 characters."
                            required
                        />
                    </FieldWrapper>
                    {getFieldErrors('name', errors)}
                </div>
            </div>
    
            <Button type='primary' htmlType='submit'>Create User</Button>
        </Form>
    </Drawer >
    
    )
}

UserCreate.Action = async ({ request }) => {


    const { name, email, type,permission, password } = Object.fromEntries(await request.formData())


    try {
        //Todo: Basic validation
        const { message: msg, data } = await createUser({
            name,
            email,
            // username,
            user_roles: [...type.split(','),...permission.split(',')], 
            password
        })
        message.success(msg)
        return redirect(generateRouteUrl('UserDetails', {
            userId: data.id
        }), {
            replace: true
        })
    }
    catch (error) {
        message.error(error.message)
        if (error instanceof ValidationError) {
            return {
                errors: error.errors
            }
        }
    }
    return false
}

export default UserCreate