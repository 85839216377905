import _ from "lodash";

const pendingDebounces = new Map(); // Stores active debounce instances

export function globalDebounce(fn, wait, options) {
    const debouncedFn = _.debounce(
        (...args) => {
            try {
                return fn(...args);
            } finally {
                pendingDebounces.delete(debouncedFn); // Remove when execution completes
            }
        },
        wait,
        options
    );

    // Wrap to track each new debounced function call
    return (...args) => {
        pendingDebounces.set(debouncedFn, true);
        return debouncedFn(...args);
    };
}

// Function to check if any debounce is still pending
export function isAnyDebouncePending() {
    return pendingDebounces.size > 0;
}

// Function to manually clear all debounces (optional)
export function cancelAllDebounces() {
    pendingDebounces.forEach((_, fn) => fn.cancel());
    pendingDebounces.clear();
}
