// import { getStoredToken } from "../constants/auth"

import { Server } from "../constants/server.js"

export const getSecuredFileUrl = (filePath) => {
    let url = new URL(`${process.env.REACT_APP_BASE_API_URL}/${process.env.REACT_APP_VERSION_V1}/${filePath}`)
    return url?.href?.toString()
}

export const getPreview = (file) => {
    if (/image\/[a-z+.-]+/.test(file.type)) {
        return file.preview
    }
    switch (file.type) {

        case "application/pdf":
            return "/icons/filetypes/pdf.svg"
        case "application/ai":
            return "/icons/filetypes/ai.svg"
        case "application/postscript":
            return "/icons/filetypes/ai.svg"
        default:
            return "/icons/filetypes/file.svg"
    }
}
export const getFilePath = (name, project_id) => {
    let BaseUrl = new URL(`${process.env.REACT_APP_BASE_API_URL}/${process.env.REACT_APP_VERSION_V1}/file`)
    return (`${BaseUrl}/${project_id}/${name}`)
}
export const getFilePathForView = (name, project_id) => {
    let BaseUrl = new URL(`${process.env.REACT_APP_BASE_API_URL}/${process.env.REACT_APP_VERSION_V1}/file/read`)
    return (`${BaseUrl}/${project_id}/${name}`)
}

export const getAdditionalQuotationFile = (id) => {
    let BaseUrl = new URL(`${process.env.REACT_APP_BASE_API_URL}/${process.env.REACT_APP_VERSION_V1}/item/additional-quotation`)
    return (`${BaseUrl}/${id}/preview`)
}
export const isShowable = (file) => {
    const extention = file.split('.').pop()

    switch (extention) {
        case "pdf":
            return true
        case "jpg":
            return true
        case "png":
            return true
        case "jpeg":
            return true
        case "svg":
            return true
        case "xlsx":
            return true
        default:
            return false
    }
}

export const getPreviewUrl = (file, project_id) => {
    const extention = file.split('.').pop()
    switch (extention) {
        case "svg":
            return getFilePath(file, project_id)
        case "png":
            return getFilePathForView(file, project_id)
        case "jpg":
            return getFilePathForView(file, project_id)
        case "jpeg":
            return getFilePathForView(file, project_id)
        case "xlsx":
            return "/icons/filetypes/xlsx.svg"
        case "csv":
            return "/icons/filetypes/xlsx.svg"
        case "ai":
            return "/icons/filetypes/ai.svg"
        case "pdf":
            return "/icons/filetypes/pdf.svg"
        default:
            return "/icons/filetypes/file.svg"
    }
}

export const getCmsLanguageFlagUrl = (language) => {
    if (!language) return ''
    const [country, lang = null] = language.split('_')
    return Server.getPublicFileUrl(`upload/flag/${lang ?? country}.png`)
}