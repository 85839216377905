import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Layout } from "antd";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { matchRoutes, useNavigate, useNavigation, useParams } from 'react-router-dom';
import { appRoutes } from "../../app.jsx";
import { Header, SecondaryHeader } from "../header";
import Styles from './page.module.scss';
const { Content } = Layout;


function Page({ title, className, children, summary, header, subHeader, backLink = true, additionalLinks = [], footer, loading = false }) {
  const navigation = useNavigation()
  const urlParams = useParams()
  const navigate = useNavigate()

  const Skeleton = useMemo(() => {
    if (!navigation.location?.pathname) return
    const target = matchRoutes(appRoutes, navigation.location.pathname);
    const lastMatch = target?.length ? target[target.length - 1] : null;
    return lastMatch?.route?.handle?.skeleton
  }, [navigation.location])

  //Generate dynamic title
  const parsedTitle = title?.interpolate(urlParams)

  const links = useMemo(() => {
    const visibleLinks = [];
    if (backLink) {
      visibleLinks.push(
        <Button
          type="primary"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(backLink === true ? -1 : backLink)}
        >
          Back
        </Button>
      );
    }
    if (Array.isArray(additionalLinks) && additionalLinks.length > 0) {
      visibleLinks.push(...additionalLinks);
    }
    return visibleLinks;
  }, [backLink, additionalLinks]);

  return (
    <>
      <div className={Styles.header}>
        <Header title={parsedTitle} links={links} summary={summary} />
        <div className={Styles.secondaryHeaderWrapper}>
          {!!header && <SecondaryHeader content={header} backLink={false} />}
          {!!subHeader && (<div className={Styles.subHeaderWrapper}>{subHeader}</div>)}
        </div>
      </div>
      <Content className={`page ${className}`} style={{ opacity: loading || navigation.state === 'loading' ? 0.6 : 1 }}>
        <div className={Styles.innerWrapper}>
          <div className={Styles.contentWrapper}>
            {(navigation.state === 'loading' && Skeleton) ? <Skeleton location={navigation.location} /> : children}
          </div>
          {footer && (
            <div className={Styles.footer} style={{ display: 'flex', justifyContent: 'space-between' }}>
              {footer}
            </div>
          )}
        </div>
      </Content>
    </>
  );
}


Page.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  header: PropTypes.node,
  subHeader: PropTypes.node,
  footer: PropTypes.node,
  loading: PropTypes.bool,
  backLink: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  additionalLinks: PropTypes.arrayOf(PropTypes.node),
};

export default Page;
