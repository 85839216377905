import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Form, Select, Checkbox ,Space} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { convertDataTypes } from '../../../library/helpers/index.js';
import { CountrySelect, CurrencySelect, LanguageSelect } from '../../inputs';
import ProductSearch from '../../inputs/product-search.jsx';

const options = [
    {
        label: 'Item',
        value: 1,
    },
    {
        label: 'Finisher',
        value: 2,
    },
    {
        label: 'Transport',
        value: 3,
    },
    {
        label: 'Duty Fees',
        value: 4,
    }
];

const VendorFilterForm = ({ children, data, ...otherProps }) => {
    const [form] = Form.useForm()
    const clearForm = () => {
        try {
            form.setFieldsValue(Object.fromEntries(
                Object.keys(form.getFieldsValue()).map(key => ([key, null]))
            ))
            form.submit()
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <Form form={form} initialValues={convertDataTypes(data, {
            country_id: parseInt,
            language_id: parseInt,
            currency_id: parseInt,
            discount: parseInt,
            using_portal: parseInt,
            product_id: parseInt,
        })} layout='vertical'  {...otherProps}  >
            <Form.Item name='country_id' label="Country">
                <CountrySelect allowClear getPopupContainer={triggerNode => triggerNode.parentElement} />
            </Form.Item>
            <Form.Item name='language_id' label="Language">
                <LanguageSelect allowClear getPopupContainer={triggerNode => triggerNode.parentElement} />
            </Form.Item>
            <Form.Item name="vendor_type" label="Vendor type">
                <Select options={options} mode="multiple" className='toggle-token' />
            </Form.Item>
            <Form.Item name="product_id" label="Product" >
                <ProductSearch language="en" width='100%' getPopupContainer={triggerNode => triggerNode.parentElement} />
            </Form.Item>
            <Form.Item name='currency_id' label="Currency" dependencies={['country_id']} >
                <CurrencySelect />
            </Form.Item>
            <Space size={80}>
            <Form.Item name="using_portal" label="Using Portal" valuePropName="checked" getValueFromEvent={e => (e.target.checked ? 1 : 0)}>
                <Checkbox />
            </Form.Item>
            <Form.Item name='discount' label="Discount %" valuePropName="checked" getValueFromEvent={e => (e.target.checked ? 1 : 0)}>
                <Checkbox />
            </Form.Item>
            </Space>
            {children}
            <Form.Item label=" ">
                <Button htmlType="submit" type="primary" icon={<FilterOutlined />}>Apply</Button>
                <Button onClick={clearForm} style={{ marginLeft: 20 }} icon={<ReloadOutlined />}>Reset</Button>
            </Form.Item>
        </Form>
    )
}

VendorFilterForm.propTypes = {
    children: PropTypes.node,
    data: PropTypes.object,
    onFinish: PropTypes.func,
}

export default VendorFilterForm