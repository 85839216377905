import { UserAddOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import { getTemplates } from '../../api/settings';
import { Page } from '../../components/page';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { TemplateColumns } from '../../library/constants/tableColumns';

function TextTemplates(props) {
  const { title } = props;
  const { templates } = useLoaderData()
  const navigate = useNavigate()

  return (
    <Page className='users' title={title}>
      <Button type="primary" onClick={() => { navigate(generateRouteUrl('TextTemplateCreate')) }} icon={<UserAddOutlined />}>Add new Template</Button>
      <Table
        columns={TemplateColumns}
        dataSource={templates}
        rowKey="id"
        onRow={template => ({
          onClick: () => {
            navigate(generateRouteUrl('TemplateDetails', {
              templateId: template.id
            }))
          }
        })}
        sticky
      />
      <Outlet />
    </Page>
  )
}

TextTemplates.Loader = async ({ request }) => {
  const url = new URL(request.url);
  const fieldTypeId = parseInt(url.searchParams.get("filter_type_id"));
  const payload = {}
  if (fieldTypeId && !isNaN) {
    payload.field_type_id = fieldTypeId
  }
  const templates = await getTemplates(payload).catch(() => [])
  return { templates }
}

TextTemplates.propTypes = {
  title: PropTypes.string,
}

export default TextTemplates
