import { Button, Drawer, Input, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Form, useActionData, useLoaderData, useNavigate } from 'react-router-dom';
import { getTemplateDetails, updateTemplate } from '../../api/settings/template.js';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { TextTemplateTypes } from '../../library/constants/dynamic.js';
import { getFieldErrors, getFieldStatus } from '../../library/helpers/forms/index.js';
import { nestedFormdataToJson } from '../../library/helpers/index.js';
import Styles from './text-template-details.module.scss';
import { FieldWrapper } from '../../components/inputs/input-wrappers.jsx';

const TemplateDetails = () => {
    const { template } = useLoaderData()
    const navigate = useNavigate()
    const [type, setType] = useState(template.field_type_id)
    const { errors = null } = useActionData() || {}

    useEffect(() => {
        setType(template.field_type_id)
    }, [template.field_type_id])

    return (
        <Drawer open={true} mask={false} title={`Template: ${template.id}`} onClose={() => navigate('..', { replace: true, relative: 'route' })} width="50%" >
            <Form method="post" className={Styles.formWrapper}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <FieldWrapper label="Name" showRequired>
                            <Input
                                type="text"
                                name="name"
                                key={template.name}
                                defaultValue={template.name}
                                status={getFieldStatus('name', errors)}
                                required
                            />
                        </FieldWrapper>
                        {getFieldErrors('name', errors)}
                    </div>
                </div>

                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <FieldWrapper label="Value" showRequired>
                            <Input.TextArea
                                name="value"
                                key={template.value}
                                defaultValue={template.value}
                                status={getFieldStatus('value', errors)}
                                required
                            />
                        </FieldWrapper>
                        {getFieldErrors('value', errors)}
                    </div>
                </div>

                {['nl', 'be', 'de', 'fr', 'ch', 'at', 'en'].map(lang => (
                    <div key={lang} className={Styles.fieldsWrapper}>
                        <div className={Styles.inputField}>
                            <FieldWrapper label={`Translation ${lang}`}>
                                <Input.TextArea
                                    name={`meta[${lang}]`}
                                    key={template.meta?.[lang] ?? ''}
                                    defaultValue={template.meta?.[lang] ?? ''}
                                    status={getFieldStatus('meta', errors)}
                                />
                            </FieldWrapper>
                            {getFieldErrors('meta', errors)}
                        </div>
                    </div>
                ))}

                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <FieldWrapper label="Type">
                            <Select
                                key={template.field_type_id}
                                options={TextTemplateTypes}
                                fieldNames={{ value: 'id', label: 'name' }}
                                defaultValue={template.field_type_id}
                                onChange={c => setType(c)}
                                status={getFieldStatus('type', errors)}
                            />
                        </FieldWrapper>
                        {getFieldErrors('type', errors)}
                        <input type="hidden" name="field_type_id" value={type} />
                    </div>
                </div>

                <Button type="primary" htmlType="submit">Update Template</Button>
            </Form>
        </Drawer >
    )
}

TemplateDetails.Action = async ({ params, request }) => {
    const { templateId } = params
    const { name, value, field_type_id, ...otherData } = Object.fromEntries(await request.formData())
    const { meta = {} } = nestedFormdataToJson(otherData)
    try {
        //Todo: Basic validation
        const msg = await updateTemplate(templateId, {
            name,
            value,
            meta,
            field_type_id: parseInt(field_type_id)
        })
        message.success(msg)
        return true
    }
    catch (error) {
        message.error(error.message)
        if (error instanceof ValidationError) {
            return {
                errors: error.errors
            }
        }
    }
    return false
}

TemplateDetails.Loader = async ({ params }) => {
    const { templateId } = params
    const template = await getTemplateDetails(templateId)
    return { template }
}

export default TemplateDetails