import { Button } from "antd";
import PropTypes from 'prop-types';
import React from "react";
import { Form } from 'react-router-dom';
import { productUrl } from "../../../library/helpers/product_url.js";
import { GeneralFieldWrapper, InputWrapper } from "../../inputs/input-wrappers.jsx";
import styles from './order-manager-studio.module.scss';

const SendToStudio = ({ orderDetails, specification, description, languageCode, heading, loading, onSubmit }) => {
    const url = orderDetails?.product?.product_link ? productUrl(orderDetails.product.product_link, languageCode) : '';
console.log({orderDetails});

    return (
        <Form className={styles.tabContainer} onSubmit={e => {
            e.preventDefault()
            onSubmit(new FormData(e.currentTarget))
        }}>
            <h3>{heading}</h3>
            {!!description && <>{description}<br /><br /></>}
            <p>Product Link from vendor</p>
            <a href={url} target='_blank' rel="noreferrer" style={{ color: '#1491ff' }}>{url}</a>
            <p style={{ 'marginTop': '16px', 'marginBottom': '16px' }}>Specifications : </p>
            <div className={styles.specifications}>
                {specification.map(({ name, value }) => (
                    <div key={name}>
                        <div className={styles.name}>{name}</div>
                        <div className={styles.value}>{value}</div>
                    </div>
                ))}
            </div>
            <div className="form-fields-wrapper--vertical">
                <InputWrapper
                    name="comment"
                    placeholder="Message for studio"
                    label="Add a Comment"
                    textarea
                />
                <GeneralFieldWrapper style={{ alignItems: 'flex-start' }} horizontal>
                    <Button htmlType="submit" type="primary" loading={loading}>Send to Studio</Button>
                </GeneralFieldWrapper>
            </div>
        </Form>
    )
}
SendToStudio.propTypes = {
    orderDetails: PropTypes.object.isRequired,
    heading: PropTypes.string,
    description: PropTypes.string,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    languageCode: PropTypes.string.isRequired,
    specification: PropTypes.string
}
export default SendToStudio