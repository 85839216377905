import {
	CheckOutlined,
	CloseOutlined,
	DeleteOutlined,
	ExportOutlined,
	FormOutlined,
	LinkOutlined,
	SendOutlined
} from '@ant-design/icons';
import { Button, Checkbox, Collapse, Drawer, Form, Statistic, Table, message } from 'antd';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Await, Link, Outlet, defer, redirect, useFetcher, useLoaderData } from 'react-router-dom';
import { approvePurchaseBill, getProjectDetails, getPurchaseBillDetails, getPurchaseBillProjectDetails, getPurchaseBills, onholdPurchaseBill, purchaseBillAuditTrail, recordManualPaymentPurchaseBill, removeOnholdPurchaseBill, removePurchaseBill, revokePurchaseBill, sendToTwinfield, setInvoicePaidOnline, twinfieldManualUpdate, unsetInvoicePaidOnline, updateCustomAmount } from '../../api/finance';
import { getVendorDetails } from '../../api/vendor/vendor.js';
import { ActionCenter } from '../../components/design/action-center.jsx';
import SmallSection from '../../components/design/small-section.jsx';
import Currency from '../../components/inputs/Currency.jsx';
import { FieldWrapper, GeneralFieldWrapper, InputWrapper } from '../../components/inputs/input-wrappers.jsx';
import Confirm from '../../components/message/Confirm.jsx';
import { Page } from '../../components/page';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { ProjectStatusesDetails, PurchaseBillAuditTrailColumns, Server } from '../../library/constants';
import { ERP_CURRENCY, findVendorPaymentTermById } from '../../library/constants/dynamic.js';
import { PurchaseBillStatusDetails, PurchaseBillStatuses } from '../../library/constants/purchase-bill-statuses.js';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { convertDataTypes, getCurrencyById, getFormattedId } from '../../library/helpers';
import { formatDate, formatPrice } from '../../library/utilities/intl.js';
import Styles from './purchasebills-basic-details.module.scss';

const { Panel } = Collapse

const RelatedPurchaseBillsColumns = [
	{
		title: '',
		dataIndex: 'id',
		fixed: 'left',
		render: id => <Link to={generateRouteUrl('FinancePurchaseBillDetails', { purchaseBillId: id })}>{getFormattedId(id)}</Link>
	},
	{
		title: 'Vendor',
		fixed: 'left',
		dataIndex: ['vendor', 'company']
	},
	{
		title: 'Invoice No',
		fixed: 'left',
		dataIndex: 'invoice_number'
	},
	{
		title: 'Status',
		dataIndex: 'status',
		render: status => PurchaseBillStatusDetails[status]
	},
	{
		title: 'Amount',
		dataIndex: 'total_price_incl_vat',
		render: (val, record) => {
			const currency = getCurrencyById(record.currency_id)?.code
			if (ERP_CURRENCY === currency) {
				return formatPrice(val, currency)
			}
			return formatPrice(record.total_price_incl_vat_converted?.[currency], currency)
		}
	},
	{
		title: 'Custom Amount',
		dataIndex: 'custom_amount_to_pay',
		render: (val, record) => {
			const currency = getCurrencyById(record.currency_id)?.code
			if (ERP_CURRENCY === currency) {
				return formatPrice(val, currency)
			}
			return formatPrice(record.custom_amount_to_pay_converted?.[currency], currency)
		}
	},
	{
		title: 'Creation Date',
		dataIndex: 'invoice_date',
		render: date => formatDate(date)
	},
	{
		title: 'Due Date',
		dataIndex: 'due_date',
		render: date => formatDate(date)
	}, {
		title: 'file',
		dataIndex: ['email_read', 'attachments'],
		render: attachments => {
			if (!attachments) return
			return attachments.map(attachment => (
				<Link key={attachment} to={Server.getFileUrl(`document/attachment/${attachment}`)} target="_blank" reloadDocument download>View&nbsp;</Link>
			))
		}
	}
]

const CustomAmountSection = () => {
	const { purchaseBillDetails, vendorDetails } = useLoaderData()
	const [form] = Form.useForm();
	const discount = Form.useWatch('discount', form);
	const fetcher = useFetcher()
	const debouncedSubmit = debounce((_, data) => {
		const payload = {
			action: 'updateCustomAmount'
		}

		if (!data?.discount) {
			payload.custom_amount_to_pay = data?.custom_amount_to_pay
			payload.discount = false
		}
		else {
			payload.discount = true
		}
		fetcher.submit(payload, { method: 'post', encType: 'application/json' })
	}, 500);

	return (
		<Form form={form} initialValues={{
			discount: !!purchaseBillDetails.discount,
			custom_amount_to_pay: Number(purchaseBillDetails.custom_amount_to_pay)
		}} onValuesChange={debouncedSubmit} className={Styles.fieldsWrapper} layout="horizontal">
			<Form.Item name="custom_amount_to_pay" label="Custom Amount to Pay">
				<Currency currency={purchaseBillDetails.currency.code} disabled={discount} />
			</Form.Item>
			<Form.Item name="discount" label="Discount" valuePropName="checked">
				<Checkbox />
			</Form.Item>
			<GeneralFieldWrapper label="Vendor IBAN" horizontal>
				<>
					{vendorDetails.iban}
				</>
			</GeneralFieldWrapper>
		</Form>
	)
}

const TwinfieldSection = () => {
	const { purchaseBillDetails } = useLoaderData()
	const fetcher = useFetcher()
	const [isEditable, setIsEditable] = useState(false)
	const { errors = null } = fetcher.data || {}

	return (
		<Collapse defaultActiveKey={['1']} expandIconPosition="end">
			<Panel header={<><img src="/icons/headericons/twinfield.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }} /> Twinfield</>} key="1" extra={
				<Button onClick={(event) => {
					event.stopPropagation()
					setIsEditable(true)
				}} icon={<FormOutlined />} type="link" />
			}>
				<fetcher.Form method="post" className={Styles.fieldsWrapper}>
					<input type="hidden" name="action" value="twinfieldUpdate" />
					<InputWrapper defaultValue={purchaseBillDetails.twinfield_id} name="twinfield_id" label="Twinfield Number" errors={errors} disabled={!isEditable} horizontal />
					<FieldWrapper name="send_to_twinfield" label="Send To Twinfield" disabled={!isEditable} errors={errors} horizontal>
						{/* <input type="hidden" name="send_to_twinfield" value={0} /> */}
						<Checkbox name="send_to_twinfield" defaultChecked={purchaseBillDetails.send_to_twinfield} disabled={!isEditable} />
					</FieldWrapper>
					{isEditable && (
						<GeneralFieldWrapper horizontal>
							<Button type="primary" htmlType='submit' loading={fetcher.state === 'submitting'}>Save</Button>
						</GeneralFieldWrapper>
					)}
				</fetcher.Form>
			</Panel>
		</Collapse >
	)
}

const PurchaseBillItem = ({ item }) => {
	return (
		<SmallSection header={<h3>{getFormattedId(item.id)} - {item.product.name}</h3>}>
			<div className={Styles.fieldsWrapper} style={{ gap: '0.5rem' }}>
				<div className={Styles.descriptionBubble}>
					<div className={Styles.DescriptionTitle}>Budget Purchase Price</div>
					<div className={Styles.DescriptionValue}>{formatPrice(item.vendor_budget_price.purchase_price, ERP_CURRENCY)}</div>
				</div>
				<div className={`${Styles.descriptionBubble} badge-success`}>
					<div className={Styles.DescriptionTitle}>Real Purchase Price</div>
					<div className={Styles.DescriptionValue}>{formatPrice(item.vendor_real_price.unit_price_incl, ERP_CURRENCY)}</div>
				</div>
				<div className={Styles.descriptionBubble}>
					<div className={Styles.DescriptionTitle}>Quantity</div>
					<div className={Styles.DescriptionValue}>{item.vendor_budget_price.quantity}</div>
				</div>
				<div className={Styles.descriptionBubble}>
					<div className={Styles.DescriptionTitle}>Budget Finishing Price</div>
					<div className={Styles.DescriptionValue}>{formatPrice(item.vendor_budget_price.finisher_price, ERP_CURRENCY)}</div>
				</div>
				<div className={`${Styles.descriptionBubble} badge-success`}>
					<div className={Styles.DescriptionTitle}>Real Finishing Price</div>
					<div className={Styles.DescriptionValue}></div>
				</div>
				<div className={Styles.descriptionBubble}>
					<div className={Styles.DescriptionTitle}>Shipped Quantity</div>
					<div className={Styles.DescriptionValue}>{item.vendor_real_price.quantity}</div>
				</div>
			</div>
		</SmallSection>
	)
}
PurchaseBillItem.propTypes = {
	item: PropTypes.object.isRequired,
	// currency: PropTypes.oneOf(['EUR', 'USD', 'GBP']).isRequired
}

const PurchaseBillDetails = ({ title }) => {
	const { purchaseBillId, purchaseBillDetails, auditTrail, projectPurchaseBills, projectItemDetails, projectDetails, vendorDetails, emailFile } = useLoaderData()
	const fetcher = useFetcher()
	const runningAction = fetcher.formData?.get('action')
	const [pdfPreview, setPdfPreview] = useState(null)
	const getAttatchmentUrl = (path) => Server.getFileUrl(`document/attachment/${path}`)

	return (
		<>
			<Page className='purchase-bill-create' title={title} 
			header={<div className='headerStats'>
				<Link to={generateRouteUrl('FinanceProjectDetails', {
					projectId: purchaseBillDetails.project_id
				})}>
					<Statistic
						title="Project ID"
						value={purchaseBillDetails.project_id}
						formatter={v => v}
					/>
				</Link>
				<Statistic
					title="Project Status"
					value={purchaseBillDetails.projectStatus?.name}
				/>
				<Link to={generateRouteUrl('VendorUpdate', {
					id: purchaseBillDetails.vendor_id
				})}>
					<Statistic
						title="Company Name"
						value={purchaseBillDetails.vendor.company}
					/>
				</Link>
				<Statistic
					title="Payment Term"
					value={vendorDetails.payment_term ? findVendorPaymentTermById(vendorDetails.payment_term) : ''}
				/>
				<Statistic
					title="Total Price"
					value={formatPrice(purchaseBillDetails.total_price_incl_vat, purchaseBillDetails.currency?.code)}
				/>
				<Statistic
					title="Status"
					value={PurchaseBillStatusDetails[purchaseBillDetails.status]}
				/>
				<Statistic
					title="Payment Status"
					value={purchaseBillDetails.payment_status === 1 ? "Paid" : "Not Paid"}
				/>
				{/* <Statistic
				title="Invoiced %"
				value={100 * purchaseBillDetails.paid_amount + 100 / purchaseBillDetails.amount}
				precision={0}
				suffix='%'
			/> */}
				<Statistic
					title="Acc. Manager"
					value={projectDetails.sales_manager?.name}
				/>
			</div>}
			>
				
				<ActionCenter actions={[
					{
						action: (
							<fetcher.Form method="delete">
								<Button
									icon={<CloseOutlined />}
									type="primary"
									htmlType="submit"
									name="action"
									value="approvePurchaseBill"
									loading={runningAction === 'approvePurchaseBill'}
									ghost
								>Revoke Approval</Button>
							</fetcher.Form>
						),
						isVisible: purchaseBillDetails.status === PurchaseBillStatuses.APPROVED
					},
					{
						action: (
							<fetcher.Form method="post">
								<Button
									icon={<CheckOutlined />}
									type="primary"
									htmlType="submit"
									name="action"
									value="approvePurchaseBill"
									loading={runningAction === 'approvePurchaseBill'}
									ghost
								>Approve</Button>
							</fetcher.Form>
						),
						isVisible: purchaseBillDetails.status === PurchaseBillStatuses.NEW
					},
					{
						action: (
							<fetcher.Form method="post">
								<Button
									icon={<SendOutlined />}
									type="primary"
									htmlType="submit"
									name="action"
									value="sendToTwinfield"
									loading={runningAction === 'sendToTwinfield'}
									ghost
								>Send to Twinfield</Button>
							</fetcher.Form>
						),
						isVisible: purchaseBillDetails.status === PurchaseBillStatuses.NEW
					},
					{
						action: (
							<fetcher.Form method="post">
								<Button
									icon={<img src="/icons/manualpayment.svg" style={{ marginRight: "8px" }} />}
									type="primary"
									htmlType="submit"
									name="action"
									value="manualPayment"
									loading={runningAction === 'manualPayment'}
									ghost
								>Manual Payment</Button>
							</fetcher.Form>
						),
						isVisible: [PurchaseBillStatuses.NEW, PurchaseBillStatuses.APPROVED].includes(purchaseBillDetails.status)
					},
					{
						action: (
							<fetcher.Form method="post">
								<Button
									icon={<img src="/icons/onhold.svg" style={{ marginRight: "8px",width:"0.8rem"}} />}
									type="primary"
									htmlType="submit"
									name="action"
									value="onHold"
									loading={runningAction === 'onHold'}
									ghost
								>On Hold</Button>
							</fetcher.Form>
						),
						isVisible: purchaseBillDetails.status === PurchaseBillStatuses.NEW
					},
					{
						action: (
							<fetcher.Form method="delete">
								<Button
									type="primary"
									htmlType="submit"
									name="action"
									value="onHold"
									loading={runningAction === 'onHold'}
									ghost
								>Unhold</Button>
							</fetcher.Form>
						),
						isVisible: purchaseBillDetails.status === PurchaseBillStatuses.ON_HOLD
					},
					{
						action: (
							<Link to={generateRouteUrl('PurchaseBillLink', {
								purchaseBillId
							})}>
								<Button icon={<LinkOutlined />}>Link Project</Button>
							</Link>
						),
						isVisible: purchaseBillDetails.status === PurchaseBillStatuses.NEW
					},
					{
						action: (
							<Confirm
								type="danger"
								htmlType="submit"
								name="action"
								value="removePurchaseBill"
								icon={<DeleteOutlined />}
								loading={runningAction === 'removePurchaseBill'}
								title="Delete Project"
								content="Are you sure you want to delete the Purchase Bill ?"
								onConfirm={() => {
									fetcher.submit({
										action: 'removePurchaseBill'
									}, {
										method: 'post',
									})
								}}
							>Remove Purchase Bill</Confirm>
						),
						isVisible: purchaseBillDetails.status === PurchaseBillStatuses.NEW
					},
					{
						action: (
							<Button onClick={() => setPdfPreview(emailFile)}>View Email PDF</Button>
						),
						isVisible: !!emailFile
					}
				]} />
				{!!purchaseBillDetails.notes && (
					<Collapse defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header={<><img src="/icons/headericons/PurchaseBillNote.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }} /> Purchase Bill Note</>} key="1">{purchaseBillDetails.notes}</Panel>
					</Collapse>
				)}
				<Outlet />
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header={<><img src="/icons/headericons/allpurchasebillsofthisproject.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }} />All Purchase Bills Of this Project</>} key="1" >
						<div className={Styles.mainWrapper}>
							<React.Suspense>
								<Await
									resolve={projectItemDetails}
									errorElement={
										<p>Error loading!</p>
									}
								>
									{({ project_items, transport_cost_lines }) => {
										const total_transport_cost = transport_cost_lines.reduce((total, curr) => total + curr.price_excl_vat, 0)
										const budgeted_transport_price = project_items.reduce((total, curr) => total + curr.vendor_budget_price.shipping_charge, 0)
										return (
											<>
												{project_items.map(item => (
													<PurchaseBillItem key={item.id} item={item} />
												))}
												<div className={Styles.fieldsWrapper} style={{ gap: '0.5rem' }}>
													<div className={Styles.descriptionBubble}>
														<div className={Styles.DescriptionTitle}>Budgeted Transport Price</div>
														<div className={Styles.DescriptionValue}>{formatPrice(budgeted_transport_price, ERP_CURRENCY)}</div>
													</div>
													<div className={`${Styles.descriptionBubble} badge-danger`}>
														<div className={Styles.DescriptionTitle}>Real Transport Price</div>
														<div className={Styles.DescriptionValue}>{formatPrice(total_transport_cost, ERP_CURRENCY)}</div>
													</div>
													<div className={Styles.descriptionBubble}>
														<div className={Styles.DescriptionTitle}>Total Price Excl. VAT</div>
														<div className={Styles.DescriptionValue}>{formatPrice(purchaseBillDetails.total_price_excl_vat_erp, ERP_CURRENCY)}</div>
													</div>
													<div className={`${Styles.descriptionBubble} badge-success`}>
														<div className={Styles.DescriptionTitle}>Duty Fee</div>
														<div className={Styles.DescriptionValue}></div>
													</div>
													<div className={Styles.descriptionBubble}>
														<div className={Styles.DescriptionTitle}>Total Price</div>
														<div className={Styles.DescriptionValue}>{formatPrice(purchaseBillDetails.total_price_incl_vat_erp, ERP_CURRENCY)}</div>
													</div>
												</div>
											</>
										)
									}}
								</Await>
							</React.Suspense>
						</div>
						<div className={Styles.mainWrapper}>
							<h4>Purchase Bills</h4>
							<React.Suspense>
								<Await
									resolve={projectPurchaseBills}
									errorElement={
										<p>Error loading purchase bills!</p>
									}
								>
									{(purchaseBills) => <Table
										columns={RelatedPurchaseBillsColumns}
										rowKey="id"
										dataSource={purchaseBills}
										rowClassName={(purchaseBills) => {
											return purchaseBills.status === 5 ? 'bg-success' : 'bg-voilet';
										}}
										pagination={false}
									/>}
								</Await>
							</React.Suspense>
						</div>
					</Panel>
				</Collapse>
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header={<><img src="/icons/headericons/payment.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }} />Payment</>} key="1" >
						<CustomAmountSection />
					</Panel>
				</Collapse>
				<TwinfieldSection />
				<React.Suspense>
					<Await
						resolve={auditTrail}
						errorElement={
							<p>Error loading audit trail!</p>
						}
					>
						{(logs) => (
							<Collapse defaultActiveKey={['1']} expandIconPosition="end">
								<Panel header={<><img src="/icons/headericons/audittrail.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }} />Audit Trail</>} key="1" >
									<Table
										columns={PurchaseBillAuditTrailColumns}
										dataSource={logs}
										rowKey="id"
										pagination={{ hideOnSinglePage: true }}
									/>
								</Panel>
							</Collapse>
						)}
					</Await>
				</React.Suspense>
			</Page >
			<Drawer title={<><Button type="primary" href={getAttatchmentUrl(pdfPreview)} icon={<ExportOutlined />} target='_blank'>View file</Button> </>} placement="right" onClose={() => setPdfPreview(null)} open={!!pdfPreview && pdfPreview.length > 0} size="large" mask={false} getContainer={false} destroyOnClose>
				<object data={getAttatchmentUrl(pdfPreview)} type="application/pdf" width="100%" height="100%">
					<p>Unable to load file.Click on the link below to view the file</p>
					<p><a target="_blank" rel="noreferrer" href={getAttatchmentUrl(pdfPreview)}>{pdfPreview}</a></p>
				</object>
			</Drawer>
		</>
	)
}

PurchaseBillDetails.propTypes = {
	title: PropTypes.string
}

PurchaseBillDetails.Actions = {
	updateCustomAmount: async ({ params, data }) => {
		const { purchaseBillId } = params
		const { discount, custom_amount_to_pay } = data
		try {
			const msg = await updateCustomAmount(purchaseBillId, discount, custom_amount_to_pay)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	approvePurchaseBill: async ({ params, method }) => {
		const { purchaseBillId } = params
		try {
			const msg = await (method === 'DELETE' ? revokePurchaseBill(purchaseBillId) : approvePurchaseBill(purchaseBillId))
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	manualPayment: async ({ params }) => {
		const { purchaseBillId } = params
		try {
			const msg = await recordManualPaymentPurchaseBill(purchaseBillId)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	onHold: async ({ params, method }) => {
		const { purchaseBillId } = params
		try {
			const msg = await (method === 'DELETE' ? removeOnholdPurchaseBill(purchaseBillId) : onholdPurchaseBill([purchaseBillId]))
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	removePurchaseBill: async ({ params }) => {
		const { purchaseBillId } = params
		try {
			const msg = await removePurchaseBill(purchaseBillId)
			message.success(msg)
			return redirect(generateRouteUrl('PurchaseBills'))
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	changePaidOnline: async ({ params, method }) => {
		const { purchaseBillId } = params
		try {
			await (method === 'DELETE' ? unsetInvoicePaidOnline(purchaseBillId) : setInvoicePaidOnline(purchaseBillId))
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	sendToTwinfield: async ({ params }) => {
		const { purchaseBillId } = params
		try {
			const msg = await sendToTwinfield(purchaseBillId)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	twinfieldUpdate: async ({ params, data }) => {
		const { purchaseBillId } = params
		// Transform the send_to_twinfield value to 1 or 0
		data.send_to_twinfield = data.send_to_twinfield === "on" ? 1 : 0;
		const { twinfield_id, send_to_twinfield } = convertDataTypes(data, {
			send_to_twinfield: parseInt
		})
		console.log({ twinfield_id, send_to_twinfield })
		try {
			const msg = await twinfieldManualUpdate(purchaseBillId, {
				twinfield_id,
				send_to_twinfield
			})
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				return {
					errors: error.errors
				}
			}
		}
		return false
	},
}

PurchaseBillDetails.Loader = async ({ params }) => {
	if (isNaN(params.purchaseBillId)) {
		throw new Error('Invalid Purchase Bill ID')
	}
	const { id: purchaseBillId, email_read: email, purchase_bill_lines, ...purchaseBillDetails } = await getPurchaseBillDetails(params.purchaseBillId)

	if (!purchaseBillDetails) {
		throw new Error('Invalid Purchase Bill')
	}

	if (!purchaseBillDetails.vendor_id) {
		return redirect(generateRouteUrl('PurchaseBillLink', {
			purchaseBillId
		}));
	}

	purchaseBillDetails.currency = getCurrencyById(purchaseBillDetails.currency_id)
	const isErpCurrency = purchaseBillDetails.currency.code === ERP_CURRENCY

	const purchaseBillLines = purchase_bill_lines.map(line => ({
		...line,
		unit_price_erp: line.unit_price,
		unit_price: isErpCurrency ? line.unit_price : line.unit_price_converted?.[purchaseBillDetails.currency.code],
		price_excl_vat_erp: line.price_excl_vat,
		price_excl_vat: isErpCurrency ? line.price_excl_vat : line.price_excl_vat_converted?.[purchaseBillDetails.currency.code],
		vat_amount_erp: line.vat_amount,
		vat_amount: isErpCurrency ? line.vat_amount : line.vat_amount_converted?.[purchaseBillDetails.currency.code],
		price_incl_vat_erp: line.price_incl_vat,
		price_incl_vat: isErpCurrency ? line.price_incl_vat : line.price_incl_vat_converted?.[purchaseBillDetails.currency.code],
	}))

	purchaseBillDetails.total_price_excl_vat_erp = purchaseBillDetails.total_price_excl_vat
	purchaseBillDetails.total_price_excl_vat = isErpCurrency ? purchaseBillDetails.total_price_excl_vat : purchaseBillDetails.total_price_excl_vat_converted?.[purchaseBillDetails.currency.code]
	purchaseBillDetails.total_price_incl_vat_erp = purchaseBillDetails.total_price_incl_vat
	purchaseBillDetails.total_price_incl_vat = isErpCurrency ? purchaseBillDetails.total_price_incl_vat : purchaseBillDetails.total_price_incl_vat_converted?.[purchaseBillDetails.currency.code]
	purchaseBillDetails.custom_amount_to_pay_erp = purchaseBillDetails.custom_amount_to_pay
	purchaseBillDetails.custom_amount_to_pay = isErpCurrency ? purchaseBillDetails.custom_amount_to_pay : purchaseBillDetails.custom_amount_to_pay_converted?.[purchaseBillDetails.currency.code]


	const [projectDetails, vendorDetails, projectItemDetails] = await Promise.all([
		getProjectDetails(purchaseBillDetails.project_id).then(data => ({
			...data,
			projectStatus: ProjectStatusesDetails.find(({ id }) => id === data.status)
		})),
		getVendorDetails(purchaseBillDetails.vendor_id),
		getPurchaseBillProjectDetails(purchaseBillDetails.project_id),
	])

	const auditTrail = purchaseBillAuditTrail(params.purchaseBillId)
	const projectPurchaseBills = getPurchaseBills({
		filter: {
			project_id: purchaseBillDetails.project_id
		}
	}).then(({ data }) => data)

	return defer({
		purchaseBillId,
		purchaseBillLines,
		purchaseBillDetails,
		// currency: getCurrencyById(purchaseBillDetails.currency_id),
		auditTrail,
		projectDetails,
		projectPurchaseBills,
		vendorDetails,
		projectItemDetails,
		emailFile: email?.attachments?.[0]
	})
}

export default PurchaseBillDetails