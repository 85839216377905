import PropTypes from 'prop-types'
import React from 'react'
import styles from './kanabanskeleton.module.scss'

const KanbanBoardSkeleton = ({ columns = 4 }) => {
    return (
        <div className="kanaban-wrapper">
            <div className={styles.kanabanWrapper}>
                <div className={styles.kanaban}>
                    {Array(columns).fill().map((_, columnIndex) => (
                        <div
                            key={columnIndex}
                            className={styles.kanabanColumnSkeleton}
                        >
                            <div className={styles.kanabanColumnHeader}>
                                <span className={styles.kanabanColumnTitle}></span>
                                <span className={styles.kanabanColumnCount}></span>
                            </div>
                            {Array(Math.floor(Math.random() * columns)).fill().map((_, itemIndex) => (
                                <div key={itemIndex} className={styles.KanbanCard}>
                                    <div className={styles.KanbanCardHeader}></div>
                                    <div className={styles.KanbanCardBody}></div>
                                    <div className={styles.KanbanCardFooter}></div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

KanbanBoardSkeleton.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    renderItem: PropTypes.func,
    onItemClick: PropTypes.func,
    isLoading: PropTypes.bool,
    hasMore: PropTypes.bool,
    loadMore: PropTypes.func,
}

export default KanbanBoardSkeleton
