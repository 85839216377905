import { InputNumber } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { localizeNumber, parseLocalizedNumber } from '../../library/utilities/intl.js';

const Currency = ({ value = null, currency = 'EUR', symbol = null, precision = 3, prefix, onChange, name = undefined, ...otherProps }) => {
    const internalPrefix = useMemo(() => {
        let _prefix = ''
        if (symbol) {
            _prefix = symbol
        }
        else {
            switch (currency) {
                case 'EUR':
                    _prefix = '€'
                    break

                case 'GBP':
                    _prefix = '£'
                    break

                case 'USD':
                    _prefix = '$'
                    break
            }
        }
        return !prefix ? _prefix : `${_prefix}\u00A0\u00A0${prefix}`

    }, [currency, symbol, prefix])

    const [val, setVal] = useState(parser(value, currency))

    return (
        <>
            <InputNumber
                value={value ?? undefined}
                // decimalSeparator={currency === 'EUR' ? ',' : '.'}
                placeholder={currency === 'EUR' ? '0,00' : '0.00'}
                style={{ width: '90%', maxWidth: '10rem', minWidth: '4rem' }}
                min={0}
                controls={false}
                {...otherProps}
                className={`currency-input${prefix ? ' has-prefix ' : ''} ${otherProps.className}`}
                formatter={val => formatter(val, currency, precision)}
                prefix={<>{internalPrefix}</>}
                onKeyDown={(e) => {
                    const allowedKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ','];
                    const functionalKeys = [
                        'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete',
                        'Tab', 'Escape', 'Enter', 'Shift', 'Control', 'Alt', 'Backspace'
                    ];
    
                    if (allowedKeys.includes(e.key) || functionalKeys.includes(e.key) || (e.ctrlKey || e.metaKey)) {
                        return;
                    }
                    if (e.key === '.' && currency !== 'EUR') {
                        return;
                    }
                    e.preventDefault();
                    if (e.key === '.' && currency === 'EUR') {
                        if (e.target.value.includes(',')) {
                            return;
                        }
                        const cursorPosition = e.target.selectionStart;
                        e.target.value = e.target.value.slice(0, cursorPosition) + ',' + e.target.value.slice(cursorPosition);
                        e.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
                    }
                }}
                parser={(v) => parser(v, currency)}
                onChange={(v) => {
                    setVal(v)
                    onChange(v)
                }}
                onFocus={event => event.target.select()}
                max={'999999999999999999999'}
                precision={precision}
            />
            {!!name && <input type="hidden" name={name} value={val} />}
        </>
    )
}

export const formatter = (val, currency, precision) => {
    return localizeNumber(
        val,
        currency === 'EUR' ? 'nl-NL' : 'en-US',
        precision
    )
}

export const parser = (val, currency) => {
    if (!val) return 0;
    return parseLocalizedNumber(val, currency === 'EUR' ? 'nl-NL' : 'en-US')
}



Currency.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    currency: PropTypes.oneOf(['EUR', 'USD', 'GBP']).isRequired,
    symbol: PropTypes.oneOf(['€', '£', '$']),
    precision: PropTypes.number,
    prefix: PropTypes.node,
    name: PropTypes.string,
}

export default Currency;