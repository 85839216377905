import PropTypes from 'prop-types';
import { Button } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './multifile-upload.module.scss';
import { getPreview } from '../library/constants';

const MultifileUpload = forwardRef(function MultifileUpload(_, ref) {
    const [, setFiles] = useState([])

    useEffect(() => {
        if (!ref.current) {
            ref.current = []
        }

        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => ref.current.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        multiple: true,
        maxSize: 10 * 1024 * 1024,
        maxFiles: 10,
        accept: {
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg'],
            'image/png': ['.png'],
            'application/pdf': ['.pdf'],
            'image/tiff': ['.tiff', '.tif'],
            'image/svg+xml': ['.svg'],
            'image/vnd.adobe.photoshop': ['.psd'],
            'application/postscript': ['.eps'],
            'application/eps': ['.eps'],
            'application/x-eps': ['.eps'],
            'image/eps': ['.eps'],
            'image/x-eps': ['.eps'],
        },
        onDropRejected: rejections => {
            alert(rejections.map(({ errors }) => errors.map(({ message }) => message)).join("\n"))
        },
        onDrop: acceptedFiles => {
            acceptedFiles.forEach(file => {
                addFile(file);
            });
        }
    })

    const addFile = (file) => {
        setFiles(files => {
            return [...files, Object.assign(file, {
                preview: URL.createObjectURL(file)
            })]
        })
        ref.current.push(file)
    }

    const deleteFile = (fileToDelete) => {
        // Clean up object URL
        URL.revokeObjectURL(fileToDelete.preview);

        // Remove from state
        setFiles(prevFiles => prevFiles.filter(file => file !== fileToDelete));
        ref.current = ref.current.filter(file => file !== fileToDelete);
    };

    return (
        <>
            <div {...getRootProps({ className: styles.dropArea })}>
                <div className={styles.dropIcon}>
                    <img src="/icons/upload.svg" />
                </div>
                <div className={styles.dropText}>
                    Drag and drop your artwork
                </div>
                <p>------------ OR ------------</p>
                <input className="input-zone" {...getInputProps()} />
                <div className={styles.browse}>Browse your file</div>
                <p style={{ marginTop: "10px" }}>Supported formats : JPG, JPEG, PNG, PDF, TIFF, TIF, SVG, PSD, EPS</p>
            </div>
            <div className={styles.files}>
                {ref.current.length > 0 && (
                    ref.current.map(file => (
                        <div className={styles.file} key={file.name}>
                            <img
                                src={getPreview(file)}
                                // Revoke data uri after image is loaded
                                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                            />
                            <p className="label">{file.name}</p>
                            <Button
                                type="danger"
                                onClick={() => deleteFile(file)}
                            >
                                Delete
                            </Button>
                        </div>
                    ))
                )}
            </div>
        </>
    )
})

MultifileUpload.propTypes = {
    inputName: PropTypes.string,
}

export default MultifileUpload