import { Button, Drawer, Input, message, Select } from 'antd';
import React from 'react';
import { Form, redirect, useActionData, useNavigate } from 'react-router-dom';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { getFieldErrors, getFieldStatus } from '../../library/helpers/forms/index.js';
import Styles from '../users/user-details.module.scss';
import { createTemplate } from '../../api/settings/template.js';
import { nestedFormdataToJson } from '../../library/helpers/index.js';
import { TextTemplateTypes } from '../../library/constants/dynamic.js';
import { FieldWrapper } from '../../components/inputs/input-wrappers.jsx';

const TextTemplateCreate = () => {
    const navigate = useNavigate()
    const { errors = null } = useActionData() || {}

    return (
        <Drawer open={true} mask={false} title='Template Create' onClose={() => navigate('..', { replace: true, relative: 'route' })} width="50%" >
            <Form method="post" className={Styles.formWrapper}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <FieldWrapper label="Name" showRequired>
                            <Input
                                type="text"
                                name="name"
                                status={getFieldStatus('name', errors)}
                                required
                            />
                        </FieldWrapper>
                        {getFieldErrors('name', errors)}
                    </div>
                </div>

                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <FieldWrapper label="Value" showRequired>
                            <Input.TextArea
                                name="value"
                                status={getFieldStatus('value', errors)}
                                required
                            />
                        </FieldWrapper>
                        {getFieldErrors('value', errors)}
                    </div>
                </div>

                {['nl', 'be', 'de', 'fr', 'ch', 'at', 'en'].map(lang => (
                    <div key={lang} className={Styles.fieldsWrapper}>
                        <div className={Styles.inputField}>
                            <FieldWrapper label={`Translation ${lang}`}>
                                <Input.TextArea
                                    name={`meta[${lang}]`}
                                    status={getFieldStatus('meta', errors)}
                                />
                            </FieldWrapper>
                            {getFieldErrors('meta', errors)}
                        </div>
                    </div>
                ))}

                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <FieldWrapper label="Type">
                            <Select
                                options={TextTemplateTypes}
                                fieldNames={{ value: 'id', label: 'name' }}
                            />
                        </FieldWrapper>
                        {getFieldErrors('type', errors)}
                        <input type="hidden" name="field_type_id" />
                    </div>
                </div>

                <Button type="primary" htmlType="submit">Create Template</Button>
            </Form>
        </Drawer >
    )
}

TextTemplateCreate.Action = async ({ request }) => {
    try {
        // Convert form data into an object
        const formData = Object.fromEntries(await request.formData());

        // Extract fields from formData
        const { name, value, field_type_id, ...otherData } = formData;

        // Convert meta fields into a structured object
        const { meta = {} } = nestedFormdataToJson(otherData);

        // Construct payload
        const payload = {
            name,
            value,
            meta,
            field_type_id: parseInt(field_type_id) // Convert to integer
        };

        // Call API to create template
        const msg = await createTemplate(payload);

        // Show success message
        message.success(msg);

        // Redirect to template list or details page
        return redirect(generateRouteUrl('TextTemplates')); // Change as needed
    } catch (error) {
        // Handle errors
        message.error(error.message);
        if (error instanceof ValidationError) {
            return {
                errors: error.errors
            };
        }
    }
    return false;
};

export default TextTemplateCreate;

